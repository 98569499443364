import React, { Component } from 'react';
import Conversance from './Blocks/Conversance.js';
import BaseBlock from './Blocks/BaseBlock.js';
import SmallBlock from './Blocks/SmallBlock.js';
import Header from './Header.js';
import Files from './Blocks/Files.js';
import Login from './Blocks/Login.js';
import logo_back from './Blocks/img/back_logo.png';

import Reveal from 'react-reveal/Reveal';
import ForUs from './ForUs.js';
import Swipe from 'react-easy-swipe';
import './circle.css';


class Doshik extends Component {

    constructor() {
        super();
        this.refConv = React.createRef();
        this.refKnow = React.createRef();
        this.refFeel = React.createRef();
        this.refGok = React.createRef();
        this.refSmallKnow = React.createRef();
        this.refSmallFeel = React.createRef();
        this.refSmallGok = React.createRef();

        this.state = {
            openForUs: false,
            tempValue: 55,
            data: [[0]],
            prevScreen: 0,
            modalState: false,
            currentScren: 0,
            allReportsState: false,
            mainState: true,
            adminHref: window.location.href.indexOf('adminReports') !== -1,
            currentSwipe: 0,
            swipeText: "calc(-0vw - 0px + ((((100vw - 288px)) / 2) * 0))",
            swipeVector: "",
            doSwipe: true
        };

        fetch('Doshik/Last').then(x => x.json()).then(x => {
            this.setState({ data: x }); this.refreshAll();
        });



    }

    openForUs = () => {
        this.checkModal(true);
        this.setState({ openForUs: true });
        document.body.style.background = '#0b1115';
    }
    closeForUs = () => {
        document.body.style.overflow = 'hidden'; setTimeout(() => { document.body.style.overflow = 'auto' }, 1450);
        this.checkModal(false);
        this.setState({ openForUs: false });
        document.body.style.background = '';
    }

    refreshDoshik = (data) => {
        this.setState({ data: data });
        this.refreshAll();
    }
    refreshAll = () => {


        this.refreshConv();
        this.refreshKnow();
        this.refreshFeel();
        this.refreshGok();

        this.refreshSmallKnow();
        this.refreshSmallFeel();
        this.refreshSmallGok();
    }

    setSwipe = () => {
        this.setState({ doSwipe: this.state.doSwipe ? false : true });
    }
    getMarginSwipe = (e) => {
        var current = e;

        var base_vw = 100;
        var base_const = 16;
        var block = 288;

        var sub_block = `((((${base_vw}vw - ${block}px)) / 2) * ${current})`;

        var base_style = `-${base_vw * current}vw - ${base_const * current}px`;


        var final_style = `calc(${base_style} + ${sub_block})`;
        this.setState({ swipeText: final_style });

    }


    refreshConv = () => {
        if (this.refConv.current !== null && this.state.data[0] !== undefined) {
            this.refConv.current.refresh(parseFloat(this.state.data[0][1].replace(',', '.')).toFixed(1),
                parseFloat(this.state.data[0][2].replace(',', '.')).toFixed(1),
                this.state.data[0][3],
                this.state.data);
        }

    }

    refreshFeel = () => {
        if (this.refFeel.current != null && this.state.data[2] !== undefined) {
            this.refFeel.current.refresh(parseFloat(this.state.data[2][1].replace(',', '.')).toPrecision(2), parseFloat(this.state.data[2][2].replace(',', '.')).toFixed(1), this.state.data[2][3]);
        }
    }
    refreshSmallKnow = () => {
        if (this.refSmallKnow.current != null && this.state.data[4] !== undefined) {
            var data = this.state.data;
            this.refSmallKnow.current.refresh([[data[4][1],
            parseFloat(data[4][2].replace(',', '.')).toFixed(1),
            data[4][3]],
            [data[5][1],
            parseFloat(data[5][2].replace(',', '.')).toFixed(1),
            data[5][3]],
            [data[6][1],
            parseFloat(data[6][2].replace(',', '.')).toFixed(1),
            data[6][3]]]);
        }
    }
    refreshSmallFeel = () => {
        if (this.refSmallFeel.current != null && this.state.data[7] !== undefined) {
            var data = this.state.data;
            this.refSmallFeel.current.refresh([[data[7][1],
            parseFloat(data[7][2].replace(',', '.')).toFixed(1),
            data[7][3]],
            [data[8][1],
            parseFloat(data[8][2].replace(',', '.')).toFixed(1),
            data[8][3]],
            [data[9][1],
            parseFloat(data[9][2].replace(',', '.')).toFixed(1),
            data[9][3]]]);
        }
    }
    refreshSmallGok = () => {
        if (this.refSmallGok.current != null && this.state.data[10] !== undefined) {
            var data = this.state.data;
            this.refSmallGok.current.refresh([[data[10][1],
            parseFloat(data[10][2].replace(',', '.')).toFixed(1),
            data[10][3]],
            [data[11][1],
            parseFloat(data[11][2].replace(',', '.')).toFixed(1),
            data[11][3]],
            [data[12][1],
            parseFloat(data[12][2].replace(',', '.')).toFixed(1),
            data[12][3]]]);
        }
    }
    refreshKnow = () => {
        if (this.refKnow.current != null && this.state.data[1] !== undefined) {
            this.refKnow.current.refresh(parseFloat(this.state.data[1][1].replace(',', '.')).toPrecision(2), parseFloat(this.state.data[1][2].replace(',', '.')).toFixed(1), this.state.data[1][3]);
        }
    }
    refreshGok = () => {
        if (this.refGok.current != null && this.state.data[3] !== undefined) {
            this.refGok.current.refresh(parseFloat(this.state.data[3][1].replace(',', '.')).toPrecision(2), parseFloat(this.state.data[3][2].replace(',', '.')).toFixed(1), this.state.data[3][3]);
        }
    }

    getRandom = () => {
        return Math.floor(Math.random() * Math.floor(100));
    }
    onSwipeStart(event) {

    }

    onSwipeMove = (position, event) => {

        if (this.state.doSwipe) {
            if (position.x < 0 && position.x < -10) this.setState({ swipeVector: "rigth" })
            else if (position.x > 0 && position.x > 10) this.setState({ swipeVector: "left" })
        }
    }

    onSwipeEnd = (position, event) => {
        var state = this.state.swipeVector;

        if (state === "rigth") {
            this.plus();
        }
        else if (state === "left") {
            this.minus();
        }
        this.setState({ swipeVector: "" });
    }
    plus = () => {

        var _currentSwipe = this.state.currentSwipe;
        if (_currentSwipe < 3) {
            _currentSwipe++;
            this.setState({ currentSwipe: _currentSwipe });
            this.getMarginSwipe(_currentSwipe);
        }

    }
    openallReports = () => {
        this.setState({ allReportsState: true, mainState: false });
    }
    closeallReports = () => {
        this.setState({ allReportsState: false, mainState: true });

    }
    minus = () => {

        var _currentSwipe = this.state.currentSwipe;
        if (_currentSwipe > 0) {
            _currentSwipe--;
            this.setState({ currentSwipe: _currentSwipe });
            this.getMarginSwipe(_currentSwipe);
        }

    }
    checkModal = (e) => {
        var state = e === undefined ? this.state.modalState : e;

        if (!state) {
            setTimeout(() => { document.getElementById('us').style.display = "none"; }, 1000);
        }
        else {
            document.getElementById('us').style.display = "";
        }
    }

    componentDidUpdate(prevProps) {


    }

    getScreen = (screen) => {
        console.log(screen);
        if (screen === 0) {
            return <div>
               
            </div>;
        }
        else if (screen === 1) {
            return <div>
                
            </div>;
        }
    }
    setCurrentScreen = (value) => {
        this.setState({ prevScreen: this.state.currentScren, currentScren: value });
    }
    render() {



        return (
            <Swipe
                onSwipeStart={this.onSwipeStart}
                onSwipeMove={this.onSwipeMove}
                onSwipeEnd={this.onSwipeEnd}>

                <div style={{ display: 'flex', justifyContent: 'center' }}>

                    {/*<Reveal effect="fadeInUp" duration="1" when={this.state.currentScren === 2}>
                        <div Style="position: relative;z-index: 99;">
                            <div Style={"width: 100%;left:0;top:0;height: 99vh;position: absolute;display: none;"} id="us">
                                <ForUs onCloseForUs={this.closeForUs} />
                            </div>
                        </div>
                    </Reveal >*/}
                    <div style={{ display: this.state.currentScren === 2 ? '' : 'none' }}> <ForUs prevScreen={this.state.prevScreen} changeScreen={this.setCurrentScreen} onCloseForUs={this.closeForUs} /></div>
                    <div className="main-container" style={{ maxWidth: '1208px', flexDirection: 'column' }}>
                        <div style={{ display: this.state.currentScren === 2 ? 'none' : '' }}>
                            <Header refresh={this.refreshDoshik} onOpenAllReports={this.openallReports} screen={this.state.currentScren} changeScreen={this.setCurrentScreen} onCloseAllReports={this.closeallReports} onOpenForUs={this.openForUs} />
                        </div>
                        
                        <div style={{ display: this.state.currentScren === 0 ? '': 'none' }}>
                            <div>
                                
                                <div Style="position: relative">
                                    <img src={logo_back} className="logo-back" Style="position: absolute;left: -110px;z-index: -1;" />
                                    <img src={logo_back} className="logo-back" Style="position: absolute;right: -110px;z-index: -1;margin-top: 50px;" />
                                    <div Style={`display: ${this.state.allReportsState ? "block" : "none"}`}>
                                        <Reveal when={this.state.allReportsState} effect="fadeInUp">
                                            <Files />
                                        </Reveal>
                                    </div>
                                    <div Style={`display: ${this.state.adminHref ? "" : "none"}`} >
                                        <Login />
                                    </div>


                                    <div Style={`display: ${this.state.mainState ? "flex" : "none"}`} className="blocks">
                                        <div Style={`margin-left: ${this.state.swipeText}`}>
                                            <Conversance convData={this.state.data} setSwipe={this.setSwipe} percent={this.state.data[0] === undefined ? 0 : this.state.data[0][0]} ref={this.refConv} adiText={"0.5"} tooltip={`Індекс знаходиться в діапазоні 20…60, де 60 - максимальне значення<br/><br/>Інтегральний індекс соціального самопочуття – це розроблена соціологами методика*, що показує, наскільки українці можуть задовольнити свої соціальні потреби<br /><br />Розраховується на базі відповідей на прості запитання "Чого  Вам не вистачає?". Наприклад, "Необхідного одягу", "Здоров'я", "Роботи", "Рішучості в досягненні своїх цілей" тощо. Всього у методиці наведено 20 ключових соціальних потреб<br/><br/>* IICC-20 авторства Є. І. Головахи й Н. В. Паніної`} />
                                        </div>

                                        <div>
                                            <BaseBlock ref={this.refKnow} head={"Ми знаємо"}
                                                tooltip={`Індекс знаходиться в діапазоні 0…10,<br />де 10 – максимальне значення<br><br>Розраховується на базі трьох показників:<br> - Інформованості щодо стану економіки<br> - Інформованості щодо стану політики <br> - Інформованості щодо стану охорони<br><span style="margin-left: 8px;"></span>здоров’я в Україні<br><br>Деталі в розділі методологія `}
                                                mainText={"Індекс «Ми знаємо» вимірює оцінку українцями власної поінформованості щодо ключових аспектів ситуації в країні"}
                                                adiText={"0.5"} percent={5.7} />
                                            <br />
                                            <SmallBlock ref={this.refSmallKnow} data={[["5.5", "0.5"], ["5.8", "0.0"], ["5.8", "-2.3"]]} head="Знаємо" arr_text={["Економічний стан",
                                                "Політичний стан",
                                                "Стан охорони здоров'я"]} />
                                        </div>
                                        <div>
                                            <BaseBlock ref={this.refFeel} head={"Ми відчуваємо"}
                                                tooltip={`Індекс знаходиться в діапазоні 0…10,<br />де 10 – максимальне значення <br/><br/> Розраховується на базі трьох показників: <br/>  - Емоційної стійкості (відсутності панічних<br/><span style="margin-left: 8px"></span>настроїв)<br/>  - Оптимізму (віри у позитивні зміни в<br/><span style="margin-left: 8px"></span>найближчі півроку)<br/>  - Згуртованості (відчуття єдності з<br/><span style="margin-left: 8px"></span>народом) <br/><br/> Деталі в розділі методологія`}
                                                mainText={"Індекс «Ми відчуваємо» вимірює, наскільки позитивними є емоційний стан та настрої українців"}
                                                adiText={"0.5"} percent={4.6} />
                                            <br />
                                            <SmallBlock ref={this.refSmallFeel} data={[["5.8", "0.5"], ["4.3", "0.0"], ["3.7", "-2.3"]]} head="Відчуваємо" arr_text={["Емоційна стійкість",
                                                "Оптимізм",
                                                "Згуртованість"]} />
                                        </div>
                                        <div>
                                            <BaseBlock ref={this.refGok} head={"Ми воліємо"}
                                                tooltip={`Індекс знаходиться в діапазоні 0…10,<br /> де 10 – максимальне значення <br/><br/> Розраховується на базі трьох показників:<br/>  - Готовності діяти та змінювати своє життя<br/>  - Готовності допомагати іншим, бути<br/><span style="margin-left: 8px"></span>волонтером<br/>  - Готовності лишатись в Україні (анти-<br/><span style="margin-left: 8px"></span>міграційний настрій)<br/> <br/><br/> Деталі в розділі методологія`}
                                                mainText={"Індекс «Ми воліємо» показує, наскільки українці вважаються себе рішучими та готовими до активних дій"}
                                                adiText={"0.5"} percent={4.8} />
                                            <br />
                                            <SmallBlock ref={this.refSmallGok} data={[["4.9", "0.5"], ["4.9", "0.0"], ["4.7", "-2.3"]]} head="Воліємо" arr_text={["Змінювати",
                                                "Допомагати",
                                                "Лишатись в Україні"]} />
                                        </div>
                                    </div>
                                </div>
                                <p className="main-bottom-text" Style={`display: ${this.state.allReportsState ? "none" : ""}`}>
                                    Нижче під значенням метрики показана динаміка порівняно з попередньою хвилею. Статистично значимі зміни відмічені кольором
                                </p>
                            </div>
                            <div Style={`display: ${this.state.mainState ? "contents" : "none"}`}>
                                <div className="pre-dots" >
                                    <div className="dots">
                                        <div className={`bottom-dot ${this.state.currentSwipe === 0 ? "active-dot" : ""}`}></div>
                                        <div className={`bottom-dot ${this.state.currentSwipe === 1 ? "active-dot" : ""}`}></div>
                                        <div className={`bottom-dot ${this.state.currentSwipe === 2 ? "active-dot" : ""}`}></div>
                                        <div className={`bottom-dot ${this.state.currentSwipe === 3 ? "active-dot" : ""}`}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: this.state.currentScren === 1 ? '' : 'none' }}>
                           
                            <div Style="position: relative">
                                <img src={logo_back} className="logo-back" Style="position: absolute;left: -110px;z-index: -1;" />
                                <img src={logo_back} className="logo-back" Style="position: absolute;right: -110px;z-index: -1;margin-top: 50px;" />
                                <div style={{ display: 'block' }}>
                                    <Reveal when={this.state.currentScren === 1} effect="fadeInUp">
                                        <Files />
                                    </Reveal>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Swipe>


        )
    }
}


/*
 

                <div>
                        <Header />
                    <div>

                        <div className="blocks">
                            <div>
                                <Conversance ref={this.refConv} adiText={"0.5"} percent={this.getRandom()} />
                            </div>

                            <div>
                                <BaseBlock head={"Ми знаємо"}
                                    mainText={"Шкала демонструє рівень обізнаності населення стосовно розповсюдження коронавірусу COVID-19, заходів, які вживаються тощо."}
                                    adiText={"0.5"} percent={this.getRandom()} />
                                <br />
                                <SmallBlock data={this.state.data} head="Обізнаність" arr_text={["Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad."]} />
                            </div>
                            <div>
                                <BaseBlock head={"Ми переживаємо"}
                                    mainText={"Шкала демонструє рівень емоційних переживань українців. Наскільки болісно та близько вони сприймають те, що відбувається."}
                                    adiText={"0.5"} percent={this.getRandom()} />
                                <br />
                                <SmallBlock data={this.state.data} head="Настрої" arr_text={["Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad."]} />
                            </div>
                            <div>
                                <BaseBlock head={"Ми діємо"}
                                    mainText={"Шкала демонструє наскільки готові українці діяти та наскільки вони активно діють в ситуації, що склалася."}
                                    adiText={"0.5"} percent={this.getRandom()} />
                                <br />
                                <SmallBlock data={this.state.data} head="Наміри" arr_text={["Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad."]} />
                            </div>
                        </div>
                    </div>

                </div>
 */


/*
 <div>

                    <div>

                        <div className="blocks">
                            <div>
                                <Conversance ref={this.refConv} adiText={"0.5"} percent={this.getRandom()} />
                            </div>

                            <div>
                                <BaseBlock head={"Ми знаємо"}
                                    mainText={"Шкала демонструє рівень обізнаності населення стосовно розповсюдження коронавірусу COVID-19, заходів, які вживаються тощо."}
                                    adiText={"0.5"} percent={this.getRandom()} />
                                <br />
                                <SmallBlock data={this.state.data} head="Обізнаність" arr_text={["Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad."]} />
                            </div>
                            <div>
                                <BaseBlock head={"Ми переживаємо"}
                                    mainText={"Шкала демонструє рівень емоційних переживань українців. Наскільки болісно та близько вони сприймають те, що відбувається."}
                                    adiText={"0.5"} percent={this.getRandom()} />
                                <br />
                                <SmallBlock data={this.state.data} head="Настрої" arr_text={["Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad."]} />
                            </div>
                            <div>
                                <BaseBlock head={"Ми діємо"}
                                    mainText={"Шкала демонструє наскільки готові українці діяти та наскільки вони активно діють в ситуації, що склалася."}
                                    adiText={"0.5"} percent={this.getRandom()} />
                                <br />
                                <SmallBlock data={this.state.data} head="Наміри" arr_text={["Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad.",
                                    "Commodo minim irure cupidatat dolor eu Lorem ipsum culpa elit ad."]} />
                            </div>
                        </div>
                    </div>

                </div>
 */
/*
 *  
 <div className="blocks">


                           
                            
                            
                        </div>
                        <div className="blocks">
                            
                         
                        </div>
 */
export default Doshik;