import React, { Component } from 'react';

import back_01 from './Blocks/img/back_01.png';
import back_02 from './Blocks/img/back_02.png';
import back_03 from './Blocks/img/back_03.png';
import back_04 from './Blocks/img/back_04.png';
import back_active from './Blocks/img/back_active.png';
import './you.css';
import fg_back from './Blocks/img/fg_back.png';
import insta from './Blocks/img/insta.png';
import LinkedIN from './Blocks/img/LinkedIN.png';
import you_logo_big from './Blocks/img/you_logo_big.png';
import lines from './Blocks/img/lines.png';
import fb_big from './Blocks/img/fb_big.png';

import back_black from './Blocks/img/back_black.png';
class ForUs extends Component {

    constructor(props) {
        super();

        this.state = {
            bg1: back_01,
            bg2: back_02
        };
        var n_this = this;
        window.addEventListener('load', () => {

            n_this.resize();

            window.addEventListener('resize', () => {
                n_this.resize();

            });
        });

    }
    closeForUs = () => {

        this.props.onCloseForUs();
    }
    openAll = () => {
        Array.prototype.slice.call(document.getElementsByTagName('SPAN')).forEach(function (item) {
            if (item.innerText === 'Усі звіти') { item.click(); return; }
        });
    }
    resize = () => {
        if (window.outerWidth < 768) {
            this.setState({ bg1: back_03, bg2: back_04 });
        } else {
            this.setState({ bg1: back_01, bg2: back_02 });
        }
    }


    render() {



        return (
            <div style={{ backgroundColor: '#0c1113' }}> 
                <div className="" Style="z-index: 99;">
                    <div className="back-you">
                        <div className="mob-cos">
                        </div>
                        <img id="bg_01" src={this.state.bg1} Style=" position: absolute;z-index: 0;width: 100%; left: 0;" />
                        <div className="container c-width" style={{ position: 'relative', zIndex: 1 }}>

                            <ul className="meh-ul">
                                <div Style="position: absolute;margin-top: 35px;">
                                    <div onClick={this.closeForUs} Style="display: flex;" className="c-pointer" >
                                        <img Style="margin-top: -2px;" src={back_black} />
                                        <p Style="margin-left: 1rem;text-decoration: underline;">Return</p>
                                    </div>
                                </div>
                                <li Style=""><div Style="width: 100%;height: 8px;background: #FF8206;"></div></li>
                                <li>  <p className="soc-init" Style="">Social Initiative YOUkraina</p></li>
                                <li> <img src={you_logo_big} /></li>
                                <li className="meh-li-end">
                                    <div className="fg-back" Style="">
                                        <img className="" src={fg_back} />
                                    </div>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <p className="you-text">
                        We, Factum Group, an analytical and creative team <br />of like-minded professionals, Ukrainians, who love their own <br />country, are launching a social initiative YOUkraina.
        </p>
                </div>
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p Style="color: #FF8206;" className="you-text">
                            Relevant and top-quality analysis of what we - Ukrainians - <br />know, feel and do to provide the timely <br />informing of the whole society and support <br />various initiatives based on reliable data.
            </p>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <h3 Style="font-family: Montserrat;
font-Style: normal;
font-weight: bold;
font-size: 32px;
line-height: 150%;color: #fff;">

                            What is YOUkraina?
            </h3>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <div className=""><img Style="width: 100%;" src={lines} /></div>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p className="you-text-small">
                            Regular online monitoring to understand awareness, attitudes and intentions<br />
                of Ukrainians related to key areas of life.<br />
                            <br />
                In consequent representative studies, we examine changes in thoughts, attitudes, and<br />
                behavior in areas such as family relationships, health, work, finance,<br />
                shopping, staying at home, leisure, digitalization, etc.
            </p>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <h3 Style="font-family: Montserrat;
font-Style: normal;
font-weight: bold;
font-size: 32px;
line-height: 150%;color: #fff;">

                            How does it work?
            </h3>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div Style="        display: flex;
        justify-content: left;
        width: 676px;">
                        <p Style="color: #FF8206;" className="you-text">
                            Research methodology is 100% contactless: ONLINE
            </p>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p className="you-text-small">
                            Qualitative and quantitative data collection methods mix.<br />
                Data source – access panel Opinion, totaling 350 000+ respondents in Ukraine<br />
            </p>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <h3 Style="        font-family: Montserrat;
        font-Style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 150%;
        color: #fff;">

                            Why society needs it?
            </h3>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p className="you-text-small">
                            We invite everyone who cares to join: ask your questions,<br />
                analyze what is happening in the minds and hearts of Ukrainians,<br />
                start initiatives to mutually support and protect those who need it the most.<br />
                            <br />
                We invite experts to get involved in working with the collected data, to report<br />
                important information that the country may lack at the moment, to dispel myths,<br />
                to give hope.
            </p>
                    </div>
                </div>
                <br />

                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p Style="color: #FF8206;display: none;" className="you-text">
                            We are open to new ideas that will help us all<br />
                overcome this crisis!
            </p>
                    </div>
                </div>
                <div Style="margin-top: 100px;" classNameName="container">
                    <h3 Style="        font-family: Montserrat;
        font-Style: normal;
        font-weight: bold;
        text-align: center;
        font-size: 32px;
        line-height: 150%;
        color: #FF8206;">Join us on social media:</h3>

                </div>
                <div className="container" Style="margin-top: 50px;">
                    <a href="https://www.facebook.com/YOUkraina" Style="margin-right: 46px;">  <img src={fb_big} /></a>
                    <a href="https://www.instagram.com/youkraina.com.ua/" Style="margin-right: 46px;">  <img src={insta} /></a>
                    <a href="https://www.linkedin.com/showcase/youkraina"><img src={LinkedIN} /></a>
                </div>
                <div Style="margin-top: 75px;">
                    <div className="back-you-02">
                        <img id="bg_02" src={this.state.bg2} Style="position: absolute; left: 0; width: 100%;z-index: 0;" />
                        <div className="container c-width" style={{ position: 'relative', zIndex: 1 }}>
                            <ul className="meh-ul-02">
                                <li className="meh-02-start">
                                    <img className="" Style="" src={you_logo_big} />
                                </li>
                                <li className="m-ul-f">
                                    <ul className="mob-obl">
                                        <li>
                                            <div className="m-button"><p>On a regular basis</p></div>
                                            <div className="m-button"><p>Openly </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>For free </p></div>
                                            <div className="m-button b-orange"><p>Not for commerce</p></div>
                                        </li>
                                        <li>
                                            <div className="m-button b-orange"><p>Not for politics </p></div>
                                            <div className="m-button"><p>But for everyone! </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>For you</p></div>
                                            <div className="m-button"><p>For me </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>For Ukrainians!  </p></div>
                                        </li>
                                    </ul>
                                </li>
                                <li className="d-ul-f">
                                    <div className="" >
                                        <div Style="margin-bottom: 15px;">
                                            <div Style="display: flex;">
                                                <div className="m-button"><p>On a regular basis</p></div>
                                                <div className="m-button"><p>Openly </p></div>
                                                <div className="m-button"><p>For free</p></div>
                                            </div>

                                        </div>
                                        <div className="" Style="margin-bottom: 15px;">
                                            <div Style="display: flex">
                                                <div className="m-button b-orange"><p>Not for commerce</p></div>
                                                <div className="m-button b-orange"><p>Not for politics </p></div>
                                                <div className="m-button"><p>But for everyone! </p></div>
                                            </div>

                                        </div>
                                        <div className="" Style="margin-bottom: 15px;">
                                            <div Style="display: flex">
                                                <div className="m-button"><p>For you</p></div>
                                                <div className="m-button"><p>For me </p></div>
                                                <div className="m-button"><p>For Ukrainians!  </p></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li Style="height: 0;" className="meh2-li-end">
                                    <div className="container">
                                        <a onClick={() => { this.closeForUs(); this.openAll(); }} className="download" Style="">Download report</a>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <footer Style="position: relative;">
                    <div onClick={this.closeForUs} Style="" className="c-pointer back-footer" >
                        <img Style="margin-top: -2px;" src={back_active} />
                        <p Style="margin-left: 1rem;text-decoration: underline;color: #BABFC6;">Return</p>
                    </div>
                </footer>
            </div>

        )
    }
}



export default ForUs;