import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Doshik from './Doshik.js';

import Doshik_en from './Doshik_en.js';

function App() {


    return (
        <div className="App">
           
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Doshik} />
                    <Route path="/En" component={Doshik_en} />
                    <Route path="/adminReports" component={Doshik} />
                </Switch>
            </BrowserRouter>

        </div>
    );
}

export default App;
