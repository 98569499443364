import React, { Component } from 'react';
import Admin from './Admin.js';

class Login extends Component {

    constructor(props) {
        super();
        this.state = {
            login: "",
            password: "",
            admin:
                <div Style="width: 300px;">
                    <input onInput={this.handleLogin} type="text" Style="margin-top: 1em;border-radius: 4px;padding: 1em;background-color: #232c35;color: white;font-family: Montserrat;border: 1px solid #2B3239;" placeholder="Логин" />
                    <br />
                    <input onInput={this.handlePassword} placeholder="Пароль" type="password" Style="margin-top: 1em;border-radius: 4px;padding: 1em;background-color: #232c35;color: white;font-family: Montserrat;border: 1px solid #2B3239;" />
                    <br />
                    <br />
                    <p onClick={this.changeBlock} Style="width: 80%;background-color: #232c35;text-align: center;padding: 10px;border-radius: 4px;color: #fff;border: 1px solid #2B3239;">Войти</p>
                </div>
        };
        this.fileRef = React.createRef();
    }


    changeBlock = () => {
        var url = `Doshik/Login?login=${this.state.login}&pass=${this.state.password}`;
        fetch(url).then(x => {
            console.log(x.status);
            if (x.status === 200) {
                this.setState({ admin: <Admin /> });
            }
            else {
                alert('Неправильный логин или пароль.');
            }
        })
       
    }

    handleLogin = (e) => {
        this.setState({login: e.target.value});
    }
    handlePassword = (e) => {
        this.setState({ password: e.target.value });
    }


    render() {
        return (
            <div>
                <div Style="display: flex;justify-content: center;margin-bottom: 1em;">
                    {this.state.admin}
                </div>
            </div>
        )
    }
}


export default Login;

