import React, { Component } from 'react';
import icon from './img/info.png';

import up_green from './img/up_green.png';
import up_yellow from './img/up_yellow.png';
import up_orange from './img/up_orange.png';
import up_sus from './img/up_sus.png';
import up_red from './img/up_red.png';
import down_red from './img/down_red.png';
import down_orange from './img/down_orange.png';
import down_yellow from './img/down_yellow.png';
import down_sus from './img/down_sus.png';
import down_green from './img/down_green.png';

import empty from './img/empty.png';
import close from './img/close.png';

import Flip from 'react-reveal/Flip';


class BaseBlock extends Component {

    constructor(props) {
        super();
        var raw_percent = parseInt(props.percent);
        // var percent = parseInt(raw_percent / 10);
        
        var colors = this.getColors(raw_percent, 0);
        this.state = {
            percent: parseInt(raw_percent * 5),
            _classBorder: colors[0],
            _classText: colors[1],
            infoIcon: colors[2],
            firstText: parseFloat(props.percent).toString().replace('.', ','),
            headText: props.head,
            modalState: false,
            adiTextColor: colors[3],
            tooltip: props.tooltip,
            mainText: props.mainText,
            adiText: "",
            adiTextVisible: "none",
            revelanceVisible: "none",
            revelance: "",
        };


    }
    openModal = () => {
        let { modalState } = this.state;
        this.setState({ modalState: !modalState })
       // this.setState({ modalState: true });

    }



    
    getColors = (percent, revelance) => {
        var arr = ["", "", up_green, ""];
        
        
        revelance = parseInt(revelance);
        if (percent <= 3) {
            arr[0] = "red-border";
            arr[1] = "red-text";
            arr[2] = revelance === 1 ? up_red : down_red;
            arr[3] = revelance === 0 ? "shape-of-gray" : "red-text";
        }
        else if (percent >= 4 && percent <= 6) {
            arr[0] = "yellow-border";
            arr[1] = "yellow-text";
            arr[2] = revelance === 1 ? up_yellow : down_yellow;
            arr[3] = revelance === 0 ? "shape-of-gray" : "yellow-text";

        } else if (percent >= 7) {
            arr[0] = "green-border";
            arr[1] = "green-text";
            arr[2] = revelance === 1 ? up_green : down_green;
            arr[3] = revelance === 0 ? "shape-of-gray" : "green-text";
        }
        return arr;
    }

    refresh = (p, adiText, revelance) => {
        var percent = p;
        if (adiText === undefined || adiText === "0.00" || adiText === "" || adiText === "0") {
            this.setState({
                adiTextVisible: "none"
            });
        }
        else {
            this.setState({
                adiTextVisible: ""
            });
        }
        if (revelance === undefined || revelance === "" || revelance === "0") {
            this.setState({
                revelanceVisible: "none"
            });
        }
        else {
            this.setState({
                revelanceVisible: ""
            });
        }
        if (parseFloat(adiText) > 0) {
            adiText = "+" + adiText;
        }
        if (parseFloat(adiText) === 0) adiText = "0,0";
        adiText = adiText.replace('.', ',');
        var colors = this.getColors(percent, revelance);
        this.setState({
            percent: parseInt(parseFloat(p) * 5),
            _classBorder: colors[0],
            _classText: colors[1],
            infoIcon: colors[2],
            revelance: revelance,
            adiText: adiText,
            adiTextColor: colors[3],
            firstText: (p).toString().replace('.', ','),

        });

    }


    render() {


        return (
            <div>
                <div>
                    <Flip left opposite when={this.state.modalState}>
                        <div Style={"z-index: " + (this.state.modalState ? "1" : "-1")} className="info-block-base gray-black-blue modal">

                            <div className="info-block-header">
                                <p className="info-block-header-text">{this.state.headText}</p>
                                <span className="c-pointer" onClick={this.openModal} ><img src={close} /></span>
                            </div>
                            <br />
                            <p className="modal-text" dangerouslySetInnerHTML={{
                                __html:
                                    this.state.tooltip
                            }}>
                            </p>
                        </div>
                    </Flip>
                </div>
                <div className="info-block-base">

                    <div className="info-block-header">
                        <p className="info-block-header-text">{this.state.headText}</p>
                        <span className="c-pointer" onClick={this.openModal} ><img src={icon} /></span>
                    </div>
                    <div className="chart-green-big">
                        <div Style="transform: rotate(-90deg);" class={"c100 small-chart p" + this.state.percent + " big"}>

                            <div class="slice">
                                <div className={`bar ${this.state._classBorder}`}></div>
                                <div class="fill"></div>
                            </div>
                        </div>

                        <div Style="width: 100%;height: 120px;background-color: #232C35;position: absolute;margin-top: 120px;">
                            <div Style="margin-top: -60px;text-align:center;">
                                <div>
                                    <div Style="height: 30px;width: 30px;background-color: #232C35;position: absolute;margin-top: 44px;transform: rotate(15deg);margin-left: -5px;"></div>
                                    <div Style="height: 30px;width: 30px;background-color: #232C35;position: absolute;margin-top: 44px;transform: rotate(-15deg);right: 13px;"></div>
                                </div>
                                <span className={`chart-first-number ${this.state._classText}`}>{this.state.firstText}</span>
                                <span Style={`display: ${this.state.adiTextVisible}`} className={`chart-second-number ${this.state.adiTextColor}`}>({this.state.adiText})</span>

                            </div>
                        </div>
                    </div>
                    <div className="chart-text-block">
                        <p>
                            {this.state.mainText}
                        </p>
                    </div>
                </div>
            </div>

        )
    }
}


export default BaseBlock;


/*
  <span Style={`display: ${this.state.revelanceVisible}`}><img src={this.state.infoIcon} /></span>

 */