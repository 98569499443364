import React, { Component } from 'react';

import back_01 from './Blocks/img/back_01.png';
import back_02 from './Blocks/img/back_02.png';
import back_03 from './Blocks/img/back_03.png';
import back_04 from './Blocks/img/back_04.png';
import back_active from './Blocks/img/back_active.png';
import './you.css';
import fg_back from './Blocks/img/fg_back.png';
import insta from './Blocks/img/insta.png';
import LinkedIN from './Blocks/img/LinkedIN.png';
import you_logo_big from './Blocks/img/you_logo_big.png';
import lines from './Blocks/img/lines.png';
import fb_big from './Blocks/img/fb_big.png';

import back_black from './Blocks/img/back_black.png';
class ForUs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bg1: back_01,
            bg2: back_02,
            aspectRatio: 0
        };
        var n_this = this;
        window.addEventListener('load', () => {

            n_this.resize();

            window.addEventListener('resize', () => {
                n_this.resize();

            });
        });

    }
    closeForUs = () => {

        this.props.onCloseForUs();
    }
    openAll = () => {
        Array.prototype.slice.call(document.getElementsByTagName('SPAN')).forEach(function (item) {
            if (item.innerText === 'Усі звіти') { item.click(); return; }
        });
    }
    resize = () => {
        if (window.outerWidth < 768) {
            this.setState({ bg1: back_03, bg2: back_04, aspectRatio: 1 });
        } else {
            this.setState({ bg1: back_01, bg2: back_02, aspectRatio: 0 });
        }
    }


    render() {



        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', height: this.state.aspectRatio == 0 ?'calc(100vw /720 * 301)' : 'calc(100vw / 187 * 190)', justifyContent: 'space-between' }}>
                    <img src={this.state.bg1} style={{ position: 'absolute', zIndex: '-1', top: '0', left: '0', width: '100%' }} />
                    <div style={{ padding: this.state.aspectRatio === 1 ? '0 20%': '0' }}>
                        <div style={{ width: '100%', height: '8px', backgroundColor: '#ef8f22' }}></div>
                        <p Style="margin-left: 1rem;text-decoration: underline;margin: 16px 0;display: flex;cursot: pointer;" onClick={() => this.props.changeScreen(this.props.prevScreen)}><img Style="margin-top: -2px;" src={back_black}  /> Повернутися</p>
                    </div>
                    <div>
                        <p className="soc-init">Соціальна ініціатива</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: this.state.aspectRatio == 1 ? '80vw': 'auto' }} src={you_logo_big} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <div style={{ backgroundColor: '#ef8f22', opacity: '0.8', width: this.state.aspectRatio === 1 ? '80vw':'500px', padding: '16px', display: 'flex', justifyContent: 'center', }}><img style={{}} src={fg_back} /></div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '32px' }}>
                    <div style={{ padding: this.state.aspectRatio === 1 ? '0 24px' : '' }}>
                        <p className="you-text">
                            Ми, Factum Group, аналітична й креативна команда <br />однодумців, українців, професіоналів, що люблять свою <br />країну, запускаємо соціальну ініціативу YOUкраїна
                        </p>
                        <p style={{ color: '#FF8206', marginTop: '32px' }} className="you-text">
                            Актуальна і якісна аналітика про те, що ми – українці – <br />знаємо, відчуваємо, робимо для своєчасного <br />інформування всього суспільства та підтримки <br />ініціатив, обґрунтованих достовірними даними.
                        </p>
                        <h3 style={{ fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', lineHeight: '150%', color: '#fff', marginTop: '64px' }}>
                            Що таке YOUкраїна?
                        </h3>
                        <div style={{ marginTop: '16px', marinBottom: '8px' }} className=""><img Style="width: 100%;" src={lines} /></div>
                        <p className="you-text-small">
                            Регулярний онлайн моніторинг із висвітлення обізнаності, настроїв та намірів<br />
                            українців щодо ключових сфер життя в період карантинних заходів.<br />
                            <br />
                            У серії репрезентативних досліджень ми вивчаємо зміни в думках, ставленні та<br />
                            поведінці в таких сферах, як родинні стосунки, здоров’я, робота, фінанси та<br />
                            покупки, перебування вдома, дозвілля, діджіталізація тощо.
                        </p>
                        <h3 style={{ fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', lineHeight: '150%', color: '#fff', marginTop: '64px', marginBottom: '16px' }}>
                            Як це працює?
                        </h3>
                        <p style={{ color: '#FF8206', marginBottom: '16px' }} className="you-text">
                            Методологія дослідження на 100% безконтактна: ONLINE
                        </p>
                        <p className="you-text-small">
                            Комбінація якісних та кількісних методів.<br />
                            Джерело даних – access panel Opinion, численність в Україні 350 000+<br />
                            респондентів
                        </p>

                        <h3 style={{ fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 'bold', fontSize: '32px', lineHeight: '150%', color: '#fff', marginTop: '64px', marginBottom: '16px' }}>
                            Для чого це потрібно суспільству?
                        </h3>

                        <p className="you-text-small">
                            Ми запрошуємо всіх небайдужих приєднуватись: ставити свої запитання,<br />
                            аналізувати те, що відбувається в думках та серцях українців, виступати з<br />
                            ініціативами для взаємної підтримки та захисту тих, хто цього найбільше<br />
                            потребує.<br />
                            <br />
                            Ми закликаємо експертів залучатися до роботи із зібраними даними, доносити<br />
                            важливу інформацію, якої, можливо, не вистачає наразі країні, розвіювати міфи,<br />
                            давати надію.
                        </p>
                    </div>

                    <div Style="margin-top: 100px;" classNameName="container">
                        <h3 Style="        font-family: Montserrat;font-Style: normal;font-weight: bold;text-align: center;font-size: 32px;line-height: 150%;color: #FF8206;">Приєднуйтесь до нас в соцмережах:</h3>
                    </div>
                    <div className="container" Style="margin-top: 50px;">
                        <a href="https://www.facebook.com/YOUkraina" Style="margin-right: 46px;">  <img src={fb_big} /></a>
                        <a href="https://www.instagram.com/youkraina.com.ua/" Style="margin-right: 46px;">  <img src={insta} /></a>
                        <a href="https://www.linkedin.com/showcase/youkraina"><img src={LinkedIN} /></a>
                    </div>
                </div>
                <div Style="margin-top: 75px;">
                    <div className="back-you-02" style={{ width: this.state.aspectRatio === 1 ? '90vw' : '' }}>
                        <img id="bg_02" src={this.state.bg2} Style="position: absolute; left: 0; width: 100%;z-index: -1;" />
                        <div className="container c-width" >
                            <ul className="meh-ul-02">
                                <li className="meh-02-start">
                                    <img className="" Style="" src={you_logo_big} />
                                </li>
                                <li className="m-ul-f">
                                    <ul className="mob-obl">
                                        <li>
                                            <div className="m-button"><p>Регулярно</p></div>
                                            <div className="m-button"><p>Відкрито </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>Безкоштовно </p></div>
                                            <div className="m-button b-orange"><p>Не для комерції</p></div>
                                        </li>
                                        <li>
                                            <div className="m-button b-orange"><p>Не для політики </p></div>
                                            <div className="m-button"><p>А для кожного! </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>Для тебе</p></div>
                                            <div className="m-button"><p>Для мене </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>Для українців!  </p></div>
                                        </li>
                                    </ul>
                                </li>
                                <li className="d-ul-f">
                                    <div className="" >
                                        <div Style="margin-bottom: 15px;">
                                            <div Style="display: flex;">
                                                <div className="m-button"><p>Регулярно</p></div>
                                                <div className="m-button"><p>Відкрито </p></div>
                                                <div className="m-button"><p>Безкоштовно </p></div>
                                            </div>

                                        </div>
                                        <div className="" Style="margin-bottom: 15px;">
                                            <div Style="display: flex">
                                                <div className="m-button b-orange"><p>Не для комерції</p></div>
                                                <div className="m-button b-orange"><p>Не для політики </p></div>
                                                <div className="m-button"><p>А для кожного! </p></div>
                                            </div>

                                        </div>
                                        <div className="" Style="margin-bottom: 15px;">
                                            <div Style="display: flex">
                                                <div className="m-button"><p>Для тебе</p></div>
                                                <div className="m-button"><p>Для мене </p></div>
                                                <div className="m-button"><p>Для українців!  </p></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li Style="height: 0;" className="meh2-li-end">
                                    <div className="container">
                                        <a onClick={() => { this.closeForUs(); this.openAll(); }} className="download" Style="">Завантажити звіт</a>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <footer Style="position: absolute;left: 0;width: 100vw;">
                    <div onClick={() => this.props.changeScreen(this.props.prevScreen)} Style="" className="c-pointer back-footer" >
                        <img Style="margin-top: -2px;" src={back_active} />
                        <p Style="margin-left: 1rem;text-decoration: underline;color: #BABFC6;">Повернутися</p>
                    </div>
                </footer>
            </div>
        )
    }
}



export default ForUs;


/*
 
 <div style={{ backgroundColor: '#0c1113' }}>
                <div className="" Style="z-index: 99;">
                    <div className="back-you">
                        <div className="mob-cos">
                        </div>
                        <img id="bg_01" src={this.state.bg1} Style=" position: absolute;z-index: -1;width: 100%; left: 0;" />
                        <div className="container c-width" Style="">

                            <ul className="meh-ul">
                                <div Style="position: absolute;margin-top: 35px;">
                                    <div onClick={this.closeForUs} Style="display: flex;" className="c-pointer" >
                                        <img Style="margin-top: -2px;" src={back_black} />
                                        <p Style="margin-left: 1rem;text-decoration: underline;">Повернутися</p>
                                    </div>
                                </div>
                                <li Style=""><div Style="width: 100%;height: 8px;background: #FF8206;"></div></li>
                                <li>  <p className="soc-init" Style="">Соціальна ініціатива</p></li>
                                <li> <img src={you_logo_big} /></li>
                                <li className="meh-li-end">
                                    <div className="fg-back" Style="">
                                        <img className="" src={fg_back} />
                                    </div>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <p className="you-text">
                        Ми, Factum Group, аналітична й креативна команда <br />однодумців, українців, професіоналів, що люблять свою <br />країну, запускаємо соціальну ініціативу YOUкраїна
        </p>
                </div>
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p Style="color: #FF8206;" className="you-text">
                            Актуальна і якісна аналітика про те, що ми – українці – <br />знаємо, відчуваємо, робимо для своєчасного <br />інформування всього суспільства та підтримки <br />ініціатив, обґрунтованих достовірними даними.
            </p>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <h3 Style="font-family: Montserrat;
font-Style: normal;
font-weight: bold;
font-size: 32px;
line-height: 150%;color: #fff;">

                            Що таке YOUкраїна?
            </h3>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <div className=""><img Style="width: 100%;" src={lines} /></div>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p className="you-text-small">
                            Регулярний онлайн моніторинг із висвітлення обізнаності, настроїв та намірів<br />
                українців щодо ключових сфер життя в період карантинних заходів.<br />
                            <br />
                У серії репрезентативних досліджень ми вивчаємо зміни в думках, ставленні та<br />
                поведінці в таких сферах, як родинні стосунки, здоров’я, робота, фінанси та<br />
                покупки, перебування вдома, дозвілля, діджіталізація тощо.
            </p>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <h3 Style="font-family: Montserrat;
font-Style: normal;
font-weight: bold;
font-size: 32px;
line-height: 150%;color: #fff;">

                            Як це працює?
            </h3>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div Style="        display: flex;
        justify-content: left;
        width: 676px;">
                        <p Style="color: #FF8206;" className="you-text">
                            Методологія дослідження на 100% безконтактна: ONLINE
            </p>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p className="you-text-small">
                            Комбінація якісних та кількісних методів.<br />
                Джерело даних – access panel Opinion, численність в Україні 350 000+<br />
                респондентів
            </p>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <h3 Style="        font-family: Montserrat;
        font-Style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 150%;
        color: #fff;">

                            Для чого це потрібно суспільству?
            </h3>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p className="you-text-small">
                            Ми запрошуємо всіх небайдужих приєднуватись: ставити свої запитання,<br />
                аналізувати те, що відбувається в думках та серцях українців, виступати з<br />
                ініціативами для взаємної підтримки та захисту тих, хто цього найбільше<br />
                потребує.<br />
                            <br />
                Ми закликаємо експертів залучатися до роботи із зібраними даними, доносити<br />
                важливу інформацію, якої, можливо, не вистачає наразі країні, розвіювати міфи,<br />
                давати надію.
            </p>
                    </div>
                </div>
                <br />

                <br />
                <div className="container">
                    <div Style="display: flex;

justify-content: left;

width: 676px;">
                        <p Style="color: #FF8206;display: none;" className="you-text">
                            Ми відкриті до нових ідей, які допоможуть усім нам<br />
                подолати і цю кризу!
            </p>
                    </div>
                </div>
                <div Style="margin-top: 100px;" classNameName="container">
                    <h3 Style="        font-family: Montserrat;
        font-Style: normal;
        font-weight: bold;
        text-align: center;
        font-size: 32px;
        line-height: 150%;
        color: #FF8206;">Приєднуйтесь до нас в соцмережах:</h3>

                </div>
                <div className="container" Style="margin-top: 50px;">
                    <a href="https://www.facebook.com/YOUkraina" Style="margin-right: 46px;">  <img src={fb_big} /></a>
                    <a href="https://www.instagram.com/youkraina.com.ua/" Style="margin-right: 46px;">  <img src={insta} /></a>
                    <a href="https://www.linkedin.com/showcase/youkraina"><img src={LinkedIN}/></a>
                </div>
                <div Style="margin-top: 75px;">
                    <div className="back-you-02">
                        <img id="bg_02" src={this.state.bg2} Style="position: absolute; left: 0; width: 100%;z-index: -1;" />
                        <div className="container c-width" >
                            <ul className="meh-ul-02">
                                <li className="meh-02-start">
                                    <img className="" Style="" src={you_logo_big} />
                                </li>
                                <li className="m-ul-f">
                                    <ul className="mob-obl">
                                        <li>
                                            <div className="m-button"><p>Регулярно</p></div>
                                            <div className="m-button"><p>Відкрито </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>Безкоштовно </p></div>
                                            <div className="m-button b-orange"><p>Не для комерції</p></div>
                                        </li>
                                        <li>
                                            <div className="m-button b-orange"><p>Не для політики </p></div>
                                            <div className="m-button"><p>А для кожного! </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>Для тебе</p></div>
                                            <div className="m-button"><p>Для мене </p></div>
                                        </li>
                                        <li>
                                            <div className="m-button"><p>Для українців!  </p></div>
                                        </li>
                                    </ul>
                                </li>
                                <li className="d-ul-f">
                                    <div className="" >
                                        <div Style="margin-bottom: 15px;">
                                            <div Style="display: flex;">
                                                <div className="m-button"><p>Регулярно</p></div>
                                                <div className="m-button"><p>Відкрито </p></div>
                                                <div className="m-button"><p>Безкоштовно </p></div>
                                            </div>

                                        </div>
                                        <div className="" Style="margin-bottom: 15px;">
                                            <div Style="display: flex">
                                                <div className="m-button b-orange"><p>Не для комерції</p></div>
                                                <div className="m-button b-orange"><p>Не для політики </p></div>
                                                <div className="m-button"><p>А для кожного! </p></div>
                                            </div>

                                        </div>
                                        <div className="" Style="margin-bottom: 15px;">
                                            <div Style="display: flex">
                                                <div className="m-button"><p>Для тебе</p></div>
                                                <div className="m-button"><p>Для мене </p></div>
                                                <div className="m-button"><p>Для українців!  </p></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li Style="height: 0;" className="meh2-li-end">
                                    <div className="container">
                                        <a onClick={() => { this.closeForUs(); this.openAll(); }} className="download" Style="">Завантажити звіт</a>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <footer Style="position: relative;">
                    <div onClick={this.closeForUs} Style="" className="c-pointer back-footer" >
                        <img Style="margin-top: -2px;" src={back_active} />
                        <p Style="margin-left: 1rem;text-decoration: underline;color: #BABFC6;">Повернутися</p>
                    </div>
                </footer>
            </div>
 
 
 
 */