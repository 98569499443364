import React, { Component } from 'react';



class ConvModalBlock extends Component {

    constructor(props) {
        super();
        this.state = {
            data: props.data,
            head: props.headData,
            dataBlocks: this.getBlocks(props.data)
        };

    }

    refresh = (head, data) => {

        var blocks = this.getBlocks(data);
        this.setState({
            head: head,
            data: data,
            dataBlocks: blocks
        });
            console.log(data[3]);
        /*var n_this = this;
        window.addEventListener('load', function () {
            n_this.refresh(head, data)
        });*/
    }

    getBlocks = (data) => {
        var _data = data.map((item) =>
            <div className="rigth-block">
                <div Style="min-width: 33px;">
                    <p className="right-block-data">{parseFloat(item[0].replace(',', '.')).toFixed(1).toString().replace('.', ',')}</p>
                    <p Style={`color: ${item[3] === "0" ? "" : "#56CCF2"}`} className="rigth-block-adi">{this.getAdiText(item[1])}</p>
                </div>
                <div Style="transform: rotate(180deg);">
                    {this.getPalochki(item[0])}
                </div>
                <div className="rigth-block-text">{item[2]}</div>
            </div>
        );
        return _data;
    }

    getAdiText = (e) => {
        if (e === undefined) return "0,0";
        if (e === "0,0") return e;
        var normal = parseFloat(e.replace(',', '.')).toFixed(1);
        if (normal > 0) {
            return "+" + normal.toString().replace('.', ',');
        } else {
            if (normal.toString().replace('.', ',') === '-0,0') return "0,0";
            else return normal.toString().replace('.', ',');
        }
    }

    getPalochki = (e) => {
        var temp = 1.0;
        e = parseFloat(e.replace(',', '.')).toFixed(1);
        var arr = []; for (var i = 0; i < 11; i++) {
            temp += 0.2;

            if (e < temp) {
                arr.push(0);
            }
            else {
                arr.push(1);
            }
            
        }
        var res = arr.map((i) =>
            <div className={`${i === 1 ? "enable-pal" : "disable-pal"}`}></div>
        );
        return res;
    }

    render() {



        return (
            <div>
                <div className="conv-modal-block" >
                    <div className="conv-modal-left-block" Style={`background-color: ${this.props.color};`}>
                        <span Style="display: flex;align-items: center;">
                            <span className="left-block-data">{parseFloat(this.state.head.replace(',', '.')).toFixed(1).toString().replace('.', ',')}</span>
                            <p className="left-block-text">{this.props.headText}</p>
                        </span>
                    </div>
                    {this.state.dataBlocks}
                </div>
            </div>

        )
    }
}


export default ConvModalBlock;


/*


 */