import React, { Component } from 'react';
import icon from './img/info.png';

import up_green from './img/up_green.png';
import up_yellow from './img/up_yellow.png';
import up_orange from './img/up_orange.png';
import up_sus from './img/up_sus.png';
import up_red from './img/up_red.png';
import down_red from './img/down_red.png';
import down_orange from './img/down_orange.png';
import down_yellow from './img/down_yellow.png';
import down_sus from './img/down_sus.png';
import down_green from './img/down_green.png';
import conv_arrow from './img/conv_arrow.png';


import empty from './img/empty.png';

import close from './img/close.png';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import ConvModal from './ConvModal.js';


class Conversance extends Component {

    constructor(props) {
        super();

        var percent = parseFloat(props.percent);
        var colors = this.getColors(percent, 0);
        this.convModal = React.createRef();
        this.state = {
            percent: percent,
            line_per: (((percent - 20) * 100 / 40) / 100 * 235),
            _classBorder: colors[0],
            _classText: colors[1],
            infoIcon: colors[2],
            adiText: "",
            adiTextColor: colors[3],
            adiTextVisible: "none",
            revelanceVisible: "none",
            revelance: "",
            tooltip: props.tooltip,
            bigText: this.getBigText(percent),
            modalState: false,
            flipBigModal: false,
            firstText: percent.toString().replace('.', ','),
            secondText: this.getSecondText(props.adiText),
            mobile: window.outerWidth < 768,
            convData: props.convData
        };
        var n_this = this;
        window.addEventListener('resize', function () {
            n_this.setState({ mobile: (window.outerWidth < 768)});
        });
    }
    openModal = () => {
        this.setState({ modalState: true });

    }

    getSecondText = (text) => {
        if (text === "0.0") {
            return "0,0"
        } else {
            if (text.indexOf('-') === -1) {
                return "+" + text.replace('.', ',');
            } else {
                if (text === "-0.0") return "0,0";
                return text.replace('.', ',');
            }
        }
    }


    bigModalStuff = () => {
        this.props.setSwipe();
        if (this.state.flipBigModal) {
            this.setState({ flipBigModal: false });
            Array.prototype.slice.call(document.getElementsByClassName('info-block-base')).forEach(function (item) {
                if (item.style.zIndex === '-1') {
                    item.style.zIndex = "0";
                } else {
                    item.style.zIndex = "1";
                }
               
            });
        }
        else {
            this.setState({ flipBigModal: true });
            Array.prototype.slice.call(document.getElementsByClassName('info-block-base')).forEach(function (item) {
                if (item.classList.contains('react-reveal')) {
                    if (item.style.zIndex !== '-1') {
                        item.style.zIndex = '-2';
                    }
                } else {
                    item.style.zIndex = "-1";
                }
               
                
            });
            var n_this = this;

            setTimeout(function () { n_this.convModal.current.refresh(n_this.state.convData); }, 100);
        }

    }



    closeModal = () => {

        this.setState({ modalState: false });
    }
    getColors = (p, revelance) => {
        var arr = ["", "", up_green, ""];
        console.log(revelance);
        if (p <= 60 && p >= 43) {
            arr[0] = "con-green-back";
            arr[1] = "con-green-text";
            arr[2] = parseInt(revelance) === 1 ? up_green : down_green;
            arr[3] = parseInt(revelance) === 0 ? "shape-of-gray" : "con-green-text";
        } else if (p <= 42 && p >= 41) {
            arr[0] = "con-sus-back";
            arr[1] = "con-sus-text";
            arr[2] = parseInt(revelance) === 1 ? up_sus : down_sus;
            arr[3] = parseInt(revelance) === 0 ? "shape-of-gray" : "con-sus-text";
        }
        else if (p <= 40 && p >= 30) {
            arr[0] = "con-yellow-back";
            arr[1] = "con-yellow-text";
            arr[2] = parseInt(revelance) === 1 ? up_yellow : down_yellow;
            arr[3] = parseInt(revelance) === 0 ? "shape-of-gray" : "con-yellow-text";
        } else if (p <= 28 && p >= 29) {
            arr[0] = "con-orange-back";
            arr[1] = "con-orange-text";
            arr[2] = parseInt(revelance) === 1 ? up_orange : down_orange;
            arr[3] = parseInt(revelance) === 0 ? "shape-of-gray" : "con-orange-text";
        } else if (p <= 27 && p >= 20) {
            arr[0] = "con-red-back";
            arr[1] = "con-red-text";
            arr[2] = parseInt(revelance) === 1 ? up_red : down_red;
            arr[3] = parseInt(revelance) === 0 ? "shape-of-gray" : "con-red-text";
        }
        return arr;
    }

    refresh = (p, adiText, revelance, convData) => {
        var percent = p;
      
        var temp_p = temp_p = parseFloat(percent.replace(',', '.'));
        if (p > parseFloat(percent)) {
            temp_p = parseFloat(percent.replace(',', '.'));
        }
        else {
            // temp_p = parseFloat(percent.replace(',', '.')) * 10
        }
        console.log(adiText);
        if (adiText === undefined || adiText === "0.00" || adiText === "" || adiText === "0") {
            this.setState({
                adiTextVisible: "none"
            });
        }
        else {
            this.setState({
                adiTextVisible: ""
            });
        }
        if (revelance === undefined || revelance === "" || revelance === "0") {
            this.setState({
                revelanceVisible: "none"
            });
        }
        else {
            this.setState({
                revelanceVisible: ""
            });
        }
        var colors = this.getColors(Math.ceil(percent), revelance);
        this.setState({
            percent: temp_p,
            _classBorder: colors[0],
            line_per: Math.abs(((((temp_p) - 20) * 100 / 40) / 100 * 235)),
            _classText: colors[1],
            infoIcon: colors[2],
            bigText: this.getBigText(Math.ceil(percent)),
            adiTextColor: colors[3],
            firstText: (temp_p).toString().replace('.', ','),
            secondText: this.getSecondText(adiText),
            convData: convData
        });
        this.convModal.current.refresh(convData);
    }

    getBigText = (p) => {
        var ua_big_texts = ["Добре", "Більш-менш", "Посереднє", "Так собі", "Все погано"];
        var en_big_texts = ["All right", "More or less", "Normal", "So-so", "All bad"];
        var big_texts = [];
        if (window.location.href.indexOf('En') === -1) {
            big_texts = ua_big_texts;
        } else {
            big_texts = en_big_texts;
        }
        var num = 0;
      
        if (p <= 60 && p >= 43) {
            num = 0;
        } else if (p <= 42 && p >= 41) {
            num = 1;
        }
        else if (p <= 40 && p >= 30) {
            num = 2;
        } else if (p <= 28 && p >= 29) {
            num = 3;
        } else if (p <= 27 && p >= 20) {
            num = 4;
        }
        return big_texts[num];
    }

    render() {


        return (
            <div Style="position: relative;">
                
                <img className="c-pointer open-big-meh" onClick={this.bigModalStuff} src={conv_arrow} Style={`opacity: ${this.state.modalState ? "0" : "1"};right: ${this.state.flipBigModal ? "calc(-100% * 3.17 - 12px)" : "-12px"};transform: rotate(${this.state.flipBigModal ? 180 : 0}deg)`}  />
                <Fade left cascade delay={1} duration={1} when={this.state.flipBigModal}>
                    <div Style={`${this.state.mobile ? (this.state.flipBigModal ? "" : "display: none;") : ""}`}>
                        <ConvModal ref={this.convModal} data={this.state.convData} close={this.bigModalStuff} />
                    </div>
                </Fade>



                <div>
                    <Flip left when={this.state.modalState}>
                        <div Style={"z-index: " + (this.state.modalState ? "1" : "-1")} className="info-block gray-black-blue modal">

                            <div className="info-block-header">
                                <p className="info-block-header-text">{window.location.href.indexOf("En") === -1 ? "Самопочуття українців" : "Well-being of Ukrainians"}</p>
                                <span className="c-pointer" onClick={() => {
                                    if (window.innerWidth < document.documentElement.clientWidth) {

                                    }
                                    else {
                                        document.body.style.overflow = 'hidden';
                                        setTimeout(() => { document.body.style.overflow = 'auto' }, 1100);
                                    }
                                    this.closeModal();

                                }} ><img src={close} /></span>
                            </div>
                            <br />
                            <p className="modal-text" dangerouslySetInnerHTML={{
                                __html:
                                    this.state.tooltip
                            }}>
                            </p>
                        </div>
                    </Flip>
                </div>
                <div className="info-block">
                    <div Style={`opacity: ${this.state.flipBigModal ? "0" : "1"};`} className="info-block-header">
                        <p className="info-block-header-text">{window.location.href.indexOf("En") === -1 ? "Самопочуття українців" : "Well-being of Ukrainians"}</p>
                        <span className="c-pointer" onClick={() => {

                            if (window.innerWidth < document.documentElement.clientWidth) {

                            }
                            else {
                                document.body.style.overflow = 'hidden'; setTimeout(() => { document.body.style.overflow = 'auto' }, 1100);
                            }
                            this.openModal();
                        }}><img src={icon} /></span>
                    </div>
                    <div Style={`${this.state.flipBigModal ? "margin-left: -50px;" : ""}`}  className="chart-green-big">
                        <div Style="display: flex;justify-content: center;margin-bottom: 90px;position: relative;top: 15px;">
                            <div>
                                <div className="line-chart">
                                    <p Style="position: relative;margin-left: 25px;top: -10px;color: #858B93;">60</p>
                                </div>

                                <div Style={`height: ${this.state.line_per}px;`} className={`line-chart-solid ${this.state._classBorder}`}>

                                </div>
                                <p Style="margin-left: 25px;margin-top: -10px;color: #858B93;">20</p>
                            </div>

                        </div>

                        <div Style={`transition: 0.1s;transition-delay: 1s;width: 100%;height: 120px;background-color: ${this.state.flipBigModal ? ";transition: none;" : "#2C3743"};`}>
                            <div Style="margin-top: -60px;text-align:center;">

                                <span className={`chart-first-number ${this.state._classText}`}>{this.state.firstText}</span>
                                <span Style={`display: ${this.state.adiTextVisible}`} className={`chart-second-number ${this.state.adiTextColor}`}>({this.state.secondText})</span>
                                <span Style={`display: none`}><img src={this.state.infoIcon} /></span>

                                <br />
                                <br />
                                <p Style={`opacity: ${this.state.flipBigModal ? "0" : "1"};`} className={`happy-header ${this.state._classText}`}>{this.state.bigText}</p>
                                <p onClick={this.bigModalStuff} className="details">{window.location.href.indexOf("En") === -1 ? "Детально" :"Details"}</p>
                            </div>
                        </div>
                        <div Style={`transition: none;opacity: ${this.state.flipBigModal ? "0" : "1"};`} className="chart-text">
                            <p>
                                {window.location.href.indexOf("En") === -1 ? "Інтегральний індекс самопочуття українців на базі оцінки переліку ключових індикаторів" : "Integral well-being index based on key metrics assessment "}
                                <br />
                                {window.location.href.indexOf("En") === -1 ? "*Даний показник розрахований за методикою IICC-20 українських соціологів Є. І. Головахи й Н. В. Паніної." : "* This Index is calculated according to the IICC-20, solution developed by Ukrainian sociologists Y. Golovakha and N.Panina."}
                                

                            </p>
                        </div>
                    </div>

                </div>
                
            </div>

        )
    }
}
/*

*/

export default Conversance;