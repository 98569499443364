import React, { Component } from 'react';
import logo from './Blocks/img/logo.png';

import back_active from './Blocks/img/back_active.png';
import forward_active from './Blocks/img/forward_active.png';
import forward from './Blocks/img/forward.png';
import back from './Blocks/img/back.png';

import fg_logo from './Blocks/img/fg_logo.png';
import fg_logo_big from './Blocks/img/fg_logo_big.png';
import close_nav from './Blocks/img/close_nav.png';


class Header extends Component {

    constructor(props) {
        super(props);
        var temp_local = localStorage.getItem('doshik-data');
        if (temp_local === undefined || temp_local === null) {
            fetch('Doshik/GetReportsCount')
                .then(x => x.text())
                .then(x => localStorage.setItem('doshik-data', x));
            // localStorage.setItem('doshik-data', "");
        }
        else {
            fetch('Doshik/GetReportsCount')
                .then(x => x.text())
                .then(x => this.writeLocal(x));
        }
        var show = window.location.href.indexOf('reports') === -1;
        this.state = {
            menu: false,
            currentDate: 0,
            dates: [],
            openAllReport: show,
            showNew: false,
            showLang: false,
            current_back: back_active,
            toChangeTemp: "Усі звіти",
            current_forward: forward
        };

        var link_files = 'Doshik/Files';
        if (window.location.href.indexOf("En") !== -1) {
            link_files += '?en=true';
        }
        fetch(link_files).then(x => x.json()).then(x => { this.setState({ dates: x, currentDate: x.length - 1 }); this.check_arrows(); });
        var n_this = this;
        window.addEventListener('load', function () {
            n_this.onOpenAllReports();
        });
    }

    writeLocal = (data) => {
        if (parseInt(data) > parseInt(localStorage.getItem('doshik-data'))) {
            this.setState({ showNew: true });
        }

    }

    goToUa = () => {
        window.location.href = 'https://youkraina.com.ua/';// 'https://youkraina.factum-ua.com/';//'https://localhost:44360/';
    }
    goToEn = () => {
        window.location.href = 'https://youkraina.com.ua/En';//'https://youkraina.factum-ua.com/En';//'https://localhost:44360/En';
    }
    openForUs = () => {
        this.props.changeScreen(2);
        // this.props.onOpenForUs();
    }

    onOpenAllReports = (e) => {
        /*this.setState({ menu: false });
        if (!this.state.openAllReport && e !== false && this.props !== undefined) {
            fetch('Doshik/GetReportsCount')
                .then(x => x.text())
                .then(x => localStorage.setItem('doshik-data', x));
            this.setState({ openAllReport: true, toChangeTemp: window.location.href.indexOf("En") === -1 ?"Головна" : "Main", showNew: false });
            this.props.onOpenAllReports();
            this.props.changeScreen(0);
        }
        else if (this.state.openAllReport && this.props !== undefined) {
            this.setState({ openAllReport: false, toChangeTemp: window.location.href.indexOf("En") === -1 ? "Усі звіти" : "All reports" });
            this.props.onCloseAllReports();
            this.props.changeScreen(1);
        }*/
        if (e === undefined) {
            if (this.props.screen === 0) {
                this.setState({ openAllReport: false, toChangeTemp: window.location.href.indexOf("En") === -1 ? "Усі звіти" : "All reports" });



            } else if (this.props.screen === 1) {

                this.setState({ openAllReport: true, toChangeTemp: window.location.href.indexOf("En") === -1 ? "Головна" : "Main", showNew: false });

            }
        } else {
            if (this.props.screen === 0) {
                this.setState({ openAllReport: true, toChangeTemp: window.location.href.indexOf("En") === -1 ? "Головна" : "Main", showNew: false });
                this.props.changeScreen(1);
            } else if (this.props.screen === 1) {
                this.setState({ openAllReport: false, toChangeTemp: window.location.href.indexOf("En") === -1 ? "Усі звіти" : "All reports" });


                this.props.changeScreen(0);
            }
        }



    }

    normaliiPoryadok = (e) => {
        var a = [];
        for (var i = e.length - 1; i >= 0; i--) {
            a.push(e[i]);
        }
        console.log(a);
        if (a[0][0] != "1") {
            a = this.true_normal_order(a);
            console.log(a);
        }

        return a;
    }
    true_normal_order = (e) => {
        var a = [];
        var max = e.length;
        var min = 1;
        while (max >= min) {
            for (var i = 0; i < max; i++) {
                if (e[i][0] === min.toString()) {
                    a.push(e[i]);
                    min++;
                    break;
                }
            }
        }
        return a;
    }

    refreshDoshik = (i) => {
        var link = 'Doshik/GetData?file=' + this.state.dates[i];
        if (window.location.href.indexOf("En") !== -1) {
            link += '&en=true';
        }
        fetch(link)
            .then(x => x.json())
            .then(x => x[0] !== '1' ? this.normaliiPoryadok(x) : x)
            .then(x => this.props.refresh(x));
        /*
          fetch('Doshik/GetData?file=' + this.state.dates[i])
            .then(x => x.json())
            .then(x => this.props.refresh(x));
         */
    }

    forward_b = () => {
        if (this.state.currentDate < this.state.dates.length - 1) {
            var a = this.state.currentDate;
            a++;
            this.setState({ currentDate: a });
            this.check_arrows(a);
            this.refreshDoshik(a);
        }
    }

    check_arrows = (i) => {
        var state = i === undefined ? this.state.currentDate : i;

        if (this.state.dates.length === 1) {
            this.setState({ current_back: back, current_forward: forward });
        }
        else {
            if (state === 0) {
                this.setState({ current_back: back, current_forward: forward_active });

            } else if (state === this.state.dates.length - 1) {
                this.setState({ current_back: back_active, current_forward: forward });
            } else {
                this.setState({ current_back: back_active, current_forward: forward_active });
            }
        }
    }

    back_b = () => {
        if (this.state.currentDate > 0) {
            var a = this.state.currentDate;
            a--;
            this.setState({ currentDate: a });
            this.check_arrows(a);
            this.refreshDoshik(a);
        }

    }

    openMenu = () => {
        this.setState({ menu: true });
    }
    closeMenu = () => {
        this.setState({ menu: false });
    }
    setUa = () => {
        this.setState({ showLang: false });
    }
    setEn = () => {
        this.setState({ showLang: false });
    }
    render() {


        return (
            <header>
                <div Style={`display: ${this.state.menu ? "flex" : "none"}`} className="mobile-nav">

                    <div Style="margin-top: 30px;position: relative;left: calc(100% - 2rem)">

                        <img onClick={this.closeMenu} src={close_nav} />
                    </div>
                    <div className="mob-nav-list" Style="">
                        <span style={{ display: window.location.href.indexOf("En") === -1 ? '' : 'none' }}>{this.state.showNew ? <span Style="position: absolute;margin-top: -15px;margin-left: 20px;" className="new-icon">new</span> : <span></span>}</span>
                        <p onClick={this.onOpenAllReports}>{this.state.toChangeTemp}</p>
                        <p><a style={{ textDecoration: 'none', color: '#ff8206' }} href={window.location.href.indexOf("En") === -1 ? "https://youkraine.com.ua/files/YOUкраїна_methodology.pdf" : "https://youkraine.com.ua/files/YOUкраїна_methodology_eng.pdf"}>{window.location.href.indexOf("En") === -1 ? "Методологія" : "Methodology"}</a></p>
                        <p onClick={() => { this.closeMenu(); this.openForUs() }}>{window.location.href.indexOf("En") === -1 ? "Про проект" : "About the project"}</p>
                        {/*window.location.href.indexOf("En") === -1 ?
                            <p onClick={() => this.goToEn()}>En</p> : <p onClick={() => this.goToUa()}>Ua</p>*/}
                        <div style={{ display: 'flex', justifyContent: 'space-around', color: '#FF8206' }}>
                            <p style={{ paddingBottom: '8px', borderBottom: window.location.href.indexOf("En") === -1 ? '1px solid' : 'none' }} onClick={() => this.goToUa()}>Ukr</p>
                            <p style={{ paddingBottom: '8px', borderBottom: window.location.href.indexOf("En") === -1 ? 'none' : '1px solid' }} onClick={() => this.goToEn()}>Eng</p>
                        </div>

                    </div>

                </div>
                <div className="line">

                </div>
                <div className="flex">
                    <img onClick={() => { this.onOpenAllReports(false) }} className="nav-logo" src={logo} />

                    <div className="pre-menu">
                        <img onClick={() => { this.onOpenAllReports(false) }} className="nav-logo" src={logo} />
                        <a target="_blank" href="https://factum-ua.com/" className="mobile-fg-logo"><img Style="width: 90px;margin-left: 15vw;position: relative;top: 5px;" src={fg_logo_big} /></a>
                        <div className="menu-button">
                            <div onClick={this.openMenu} className="open c-pointer">
                                <div Style="width: 18px;height: 2px; background-color: #dadada;margin-bottom: 3px;"></div>
                                <div Style="width: 18px;height: 2px; background-color: #dadada;margin-bottom: 3px;"></div>
                                <div Style="width: 18px;height: 2px; background-color: #dadada;margin-bottom: 3px;"></div>
                            </div>
                            <div className="close"></div>
                        </div>
                    </div>
                    <div Style={`opacity: ${this.state.openAllReport ? "0" : "1"}`} className="nav-block">

                        <img onClick={this.back_b} src={this.state.current_back} className="arrow-back c-pointer" />
                        <i Style={`background-color: ${this.state.currentDate === this.state.dates.length - 1 ? "auto" : "#EB5757"}`} className="dot-nav"></i>
                        <span className="nav-text">{this.state.dates[this.state.currentDate]}</span>
                        <img onClick={this.forward_b} className="arrow-forward c-pointer" src={this.state.current_forward} />
                    </div>
                    <div Style={`display: ${this.state.openAllReport ? "none" : "auto"}`} className="pre-nav-block">
                        <div className="nav-block">

                            <img onClick={this.back_b} src={this.state.current_back} className="arrow-back c-pointer" />
                            <i Style={`background-color: ${this.state.currentDate === this.state.dates.length - 1 ? "auto" : "#EB5757"}`} className="dot-nav"></i>
                            <span className="nav-text">{this.state.dates[this.state.currentDate]}</span>
                            <img onClick={this.forward_b} className="arrow-forward c-pointer" src={this.state.current_forward} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', right: '1%', width: window.location.href.indexOf("En") === -1 ? '100%' : '123%' }}>
                        <div className="nav-links">
                            <span style={{ display: window.location.href.indexOf("En") === -1 ? '' : 'none' }}>{this.state.showNew ? <span Style="position: absolute;top: -16px;margin-left: 55px;" className="new-icon">new</span> : <span></span>}</span>
                            <span onClick={this.onOpenAllReports} className="nav-link c-pointer">{this.state.toChangeTemp}</span>
                            <a target="_blank" style={{ textDecoration: 'none' }} href={window.location.href.indexOf("En") === -1 ? "https://youkraine.com.ua/files/YOUкраїна_methodology.pdf" : "https://youkraine.com.ua/files/YOUкраїна_methodology_eng.pdf"} className="nav-link c-pointer">{window.location.href.indexOf("En") === -1 ? "Методологія" : "Methodology"}</a>
                            <span onClick={() => { this.openForUs(); this.onOpenAllReports(); }} className="nav-link c-pointer">{window.location.href.indexOf("En") === -1 ? "Про проект" : "About the project"}</span>
                            <div className="lang-menu">
                                <div style={{ display: '' }}>
                                    <p onClick={() => this.setState({ showLang: !this.state.showLang })}>{window.location.href.indexOf("En") === -1 ? "Ukr" : "Eng"} <img src="arrow_down.svg" /></p>
                                    <div style={{ display: this.state.showLang ? '' : 'none', position: 'absolute' }} className="lang-menu-block">
                                        <p onClick={() => this.goToUa()}>Ukr</p>
                                        <p onClick={() => this.goToEn()}>Eng</p>
                                    </div>
                                </div>

                                {/*window.location.href.indexOf("En") === -1 ?
                                    <p Style="position: relative;top: 4px;left: -10px;" onClick={() => this.goToEn()}>En</p> : <p Style="position: relative;top: 4px;left: -10px;" onClick={() => this.goToUa()}>Ua</p>
                                */}
                            </div>

                            <a target="_blank" href="https://factum-ua.com/"><img Style="heigth: 100%;" src={fg_logo} /></a>
                        </div>
                    </div>

                </div>


            </header>
        )
    }
}


export default Header;


/*


 */