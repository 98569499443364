import React, { Component } from 'react';

import quiz from './img/quiz.png';
import colocol from './img/colocol.png';
import graph from './img/graph.png';

import business_deal from './img/business_deal.png';
import business_gone from './img/business_gone.png';
import business_money from './img/business_money.png';
import business_smartphone from './img/business_smartphone.png';
import health_arms from './img/health_arms.png';
import health_hurt from './img/health_hurt.png';
import health_plant from './img/health_plant.png';
import hobby_bag from './img/hobby_bag.png';
import hobby_guitar from './img/hobby_guitar.png';
import hobby_minus_ebalo from './img/hobby_minus_ebalo.png';
import hobby_pool from './img/hobby_pool.png';
import hobby_read from './img/hobby_read.png';
import hobby_srat_na_kamni from './img/hobby_srat_na_kamni.png';
import privat_big from './img/privat_big.png';
import privat_condom from './img/privat_condom.png';
import privat_love from './img/privat_love.png';
import privat_small from './img/privat_small.png';
import science_mag from './img/science_mag.png';
import science_tele from './img/science_tele.png';
import shooping_bag from './img/shooping_bag.png';
import shooping_bag_love from './img/shooping_bag_love.png';
import shooping_beer from './img/shooping_beer.png';
import shooping_burger from './img/shooping_burger.png';
import shooping_sus from './img/shooping_sus.png';
import sport_arm from './img/sport_arm.png';
import sport_gant from './img/sport_gant.png';
import sport_stand from './img/sport_stand.png';
import sport_up from './img/sport_up.png';
import travel_bag from './img/travel_bag.png';
import travel_car from './img/travel_car.png';
import travel_palm from './img/travel_palm.png';
import travel_plane from './img/travel_plane.png'; 
import bag_work from './img/bag_work.png';
import brain from './img/brain.png';
import cake from './img/cake.png';
import chess from './img/chess.png';
import company from './img/company.png';
import corona from './img/corona.png';
import crest from './img/crest.png';
import eye from './img/eye.png';
import goblin from './img/goblin.png';
import house from './img/house.png';
import hurt from './img/hurt.png';
import lampochka from './img/lampochka.png';
import like from './img/like.png';
import mountain from './img/mountain.png';
import present from './img/present.png';
import sms from './img/sms.png';
import speed from './img/speed.png';
import stonks from './img/stonks.png';
import stop from './img/stop.png';
import three from './img/three.png';
import town from './img/town.png';



import download from './img/download.png';

class FileBlock extends Component {

    constructor(props) {
        super();
        var dict = {
            "business_deal": business_deal,
            "business_gone": business_gone,
            "business_money": business_money,
            "business_smartphone": business_smartphone,
            "health_arms": health_arms,
            "health_hurt": health_hurt,
            "health_plant": health_plant,
            "hobby_bag": hobby_bag,
            "hobby_guitar": hobby_guitar,
            "hobby_minus_ebalo": hobby_minus_ebalo,
            "hobby_pool": hobby_pool,
            "hobby_read": hobby_read,
            "hobby_srat_na_kamni": hobby_srat_na_kamni,
            "privat_big": privat_big,
            "privat_condom": privat_condom,
            "privat_love": privat_love,
            "privat_small": privat_small,
            "science_mag": science_mag,
            "science_tele": science_tele,
            "shooping_bag": shooping_bag,
            "shooping_bag_love": shooping_bag_love,
            "shooping_beer": shooping_beer,
            "shooping_burger": shooping_burger,
            "shooping_sus": shooping_sus,
            "sport_arm": sport_arm,
            "sport_gant": sport_gant,
            "sport_stand": sport_stand,
            "sport_up": sport_up,
            "travel_bag": travel_bag,
            "travel_car": travel_car,
            "travel_palm": travel_palm,
            "travel_plane": travel_plane,
            "bag_work": bag_work,
            "brain": brain,
            "cake": cake,
            "chess": chess,
            "company": company,
            "corona": corona,
            "crest": crest,
            "eye": eye,
            "goblin": goblin,
            "house": house,
            "hurt": hurt,
            "lampochka": lampochka,
            "like": like,
            "mountain": mountain,
            "present": present,
            "sms": sms,
            "speed": speed,
            "stonks": stonks,
            "stop": stop,
            "three": three,
            "town": town
        };
        var types = {
            "quiz": quiz,
            "colocol": colocol,
            "graph": graph,
            "quiz-a": quiz,
            "colocol-a": colocol,
            "graph-a": graph
        };
        var heads = {
            "quiz": "Міні-опитування",
            "colocol": "Спецвипуск",
            "graph": "Дайджест",
            "quiz-a": "Mini-survey",
            "colocol-a": "Special issue",
            "graph-a": "Digest"
        };
        this.state = {
            dict: dict,
            selected: props.icon,
          
            text: props.text,
            type: props.type,
            time: props.time,
            types: types,
            heads: heads,
            url: props.toDownload
        };

    }


    refresh = (head, text, type, selected) => {
        this.setState({
            head: head,
            text: text,
            type: type,
            selected, selected
        });
    }

    download = () => {
        var win = window.open(this.state.url, '_blank');
        win.focus();
    }
    render() {


        return (
            <div Style={`background-color: ${this.state.type === "colocol" ? "#2C3743" : "auto"}`} onClick={this.download} className="file-block">
                <div className="file-head">
                    <img Style="height: 16px; width: 16px;" src={this.state.types[this.state.type]} />
                    <p className="file-type-text">{this.state.heads[this.state.type]}</p>
                    <p className="file-time"></p>
                </div>
                <div className="file-body">
                    <img Style="margin: 5% 3%;width: 40px;height: 40px;" src={this.state.dict[this.state.selected]} />
                    <p className="file-main-text">{this.state.text}</p>
                    <img src={download} />
                </div>
            </div>
        )
    }
}


export default FileBlock;

