import React, { Component } from 'react';

import FileBlock from './FileBlock.js';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import business_deal from './img/business_deal.png';
import business_gone from './img/business_gone.png';
import business_money from './img/business_money.png';
import business_smartphone from './img/business_smartphone.png';
import health_arms from './img/health_arms.png';
import health_hurt from './img/health_hurt.png';
import health_plant from './img/health_plant.png';
import hobby_bag from './img/hobby_bag.png';
import hobby_guitar from './img/hobby_guitar.png';
import hobby_minus_ebalo from './img/hobby_minus_ebalo.png';
import hobby_pool from './img/hobby_pool.png';
import hobby_read from './img/hobby_read.png';
import hobby_srat_na_kamni from './img/hobby_srat_na_kamni.png';
import privat_big from './img/privat_big.png';
import privat_condom from './img/privat_condom.png';
import privat_love from './img/privat_love.png';
import privat_small from './img/privat_small.png';
import science_mag from './img/science_mag.png';
import science_tele from './img/science_tele.png';
import shooping_bag from './img/shooping_bag.png';
import shooping_bag_love from './img/shooping_bag_love.png';
import shooping_beer from './img/shooping_beer.png';
import shooping_burger from './img/shooping_burger.png';
import shooping_sus from './img/shooping_sus.png';
import sport_arm from './img/sport_arm.png';
import sport_gant from './img/sport_gant.png';
import sport_stand from './img/sport_stand.png';
import sport_up from './img/sport_up.png';
import travel_bag from './img/travel_bag.png';
import travel_car from './img/travel_car.png';
import travel_palm from './img/travel_palm.png';
import travel_plane from './img/travel_plane.png';
import bag_work from './img/bag_work.png';
import brain from './img/brain.png';
import cake from './img/cake.png';
import chess from './img/chess.png';
import company from './img/company.png';
import corona from './img/corona.png';
import crest from './img/crest.png';
import eye from './img/eye.png';
import goblin from './img/goblin.png';
import house from './img/house.png';
import hurt from './img/hurt.png';
import lampochka from './img/lampochka.png';
import like from './img/like.png';
import mountain from './img/mountain.png';
import present from './img/present.png';
import sms from './img/sms.png';
import speed from './img/speed.png';
import stonks from './img/stonks.png';
import stop from './img/stop.png';
import three from './img/three.png';
import town from './img/town.png';

class Admin extends Component {

    constructor(props) {
        super();

        this.fileRef = React.createRef();
        var dict = {
            "business_deal": business_deal,
            "business_gone": business_gone,
            "business_money": business_money,
            "business_smartphone": business_smartphone,
            "health_arms": health_arms,
            "health_hurt": health_hurt,
            "health_plant": health_plant,
            "hobby_bag": hobby_bag,
            "hobby_guitar": hobby_guitar,
            "hobby_minus_ebalo": hobby_minus_ebalo,
            "hobby_pool": hobby_pool,
            "hobby_read": hobby_read,
            "hobby_srat_na_kamni": hobby_srat_na_kamni,
            "privat_big": privat_big,
            "privat_condom": privat_condom,
            "privat_love": privat_love,
            "privat_small": privat_small,
            "science_mag": science_mag,
            "science_tele": science_tele,
            "shooping_bag": shooping_bag,
            "shooping_bag_love": shooping_bag_love,
            "shooping_beer": shooping_beer,
            "shooping_burger": shooping_burger,
            "shooping_sus": shooping_sus,
            "sport_arm": sport_arm,
            "sport_gant": sport_gant,
            "sport_stand": sport_stand,
            "sport_up": sport_up,
            "travel_bag": travel_bag,
            "travel_car": travel_car,
            "travel_palm": travel_palm,
            "travel_plane": travel_plane,
            "bag_work": bag_work,
            "brain": brain,
            "cake": cake,
            "chess": chess,
            "company": company,
            "corona": corona,
            "crest": crest,
            "eye": eye,
            "goblin": goblin,
            "house": house,
            "hurt": hurt,
            "lampochka": lampochka,
            "like": like,
            "mountain": mountain,
            "present": present,
            "sms": sms,
            "speed": speed,
            "stonks": stonks,
            "stop": stop,
            "three": three,
            "town": town

        };

        var date = new Date();


        this.state = {
            dict: dict,

            text: "",
            type: "quiz",
            fileName: "",
            img: "travel_plane",
            date: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`,
            data: "",
            en: false,
            loading: false

        };


        this.images =
            Object.keys(dict).map((item) =>
                <img onClick={this.selectImage} name='select-img' value={item} Style="height: 40px; width: 40px;" src={dict[item]} />
            );
        
        /* window.addEventListener('load', function () {
             document.querySelector('input').addEventListener('change', function () {
                 console.log('start read');
                 var reader = new FileReader();
                 var n_n_this = this;
                 reader.onload = function () {
                     var arrayBuffer = this.result,
                         array = new Uint8Array(arrayBuffer);
 
                     var data = "";
                       
                     array.forEach(function (e) {
                         data += e + ';'
                     });
                     n_this.setState({ data: data, fileName: n_n_this.files[0].name});
                     console.log('end read');
                     console.log(array);
                 }
                 reader.readAsArrayBuffer(this.files[0]);
 
             }, false);
         });*/
    }

    readFile = (_e) => {
        var n_this = this;
        var name = _e.target.files[0].name;
        var reader = new FileReader();
        this.setState({ loading: true });
        reader.onload = function (i) {
           
            var arrayBuffer = this.result,
                array = new Uint8Array(arrayBuffer);

            var data = "";

            array.forEach(function (e) {
                data += e + ';'
            });
            n_this.setState({ data: data, fileName: name });
            n_this.setState({ loading: false });
        }
        reader.readAsArrayBuffer(_e.target.files[0]);
    }

    refresh = (_head, _text, _type, _img) => {
        var head = _head === null ? this.state.head : _head;
        var text = _text === null ? this.state.text : _text;
        var type = _type === null ? this.state.type : _type;
        var img = _img === null ? this.state.img : _img;

        this.fileRef.current.refresh(head, text, type, img);
    }



    send = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: '"' + this.state.data + '"'
        };
        this.setState({ loading: true });
        var data = {
            fileName: this.state.fileName,
            type: this.state.type,
            text: this.state.text,
            en: this.state.en,
            img: this.state.img
        };
        fetch('Doshik/CreateReport?report=' + JSON.stringify(data), requestOptions)
            .then(x => {
                if (x.status === 200) {
                    toast("🌚Створено")
                } else {
                    toast('🍄Помилка');
                }
                this.setState({ loading: false });
            });

    }

    handleText = (e) => {
        this.setState({ text: e.target.value });
        this.refresh(null, e.target.value, null, null);
    }
    handleType = (e) => {
        this.setState({ type: e.target.value });
        this.refresh(null, null, e.target.value, null);
    }
    selectImage = (e) => {
        Array.prototype.slice.call(document.getElementsByName('select-img')).forEach(function (i) {
            i.classList.remove('selected-image');
        });
        e.target.classList.add('selected-image');
        this.setState({ img: e.target.getAttribute('value') });

        this.refresh(null, null, null, e.target.getAttribute('value'));
    }
   

    render() {

        return (
            <div>
                <div Style={`position: absolute;margin-left: 33%;background-color: black;padding: 1rem;border-radius: 16px;z-index: 1;opacity: 0.9;display: ${this.state.loading ? "" : "none"}`}>
                    <ReactLoading type={"spin"} color={"#fff"} height={375} width={375} />
                </div>
                <ToastContainer />
               
                <div Style="display: flex;width: 1208px;text-align: center;">
                    <ul>

                        <li>
                            <select onClick={this.handleType} Style="margin-bottom: 20px;width: 232px;color: #fff;background-color: #06141c;font-family: Montserrat;border: none;height: 40px;">
                                <option value="quiz">Міні-опитування</option>
                                <option value="colocol">Спецвипуск</option>
                                <option value="graph">Дайджест</option>
                                <option value="quiz-a">Mini-survey</option>
                                <option value="colocol-a">Special issue</option>
                                <option value="graph-a">Digest</option>
                            </select>
                        </li>
                        <li Style="display: flex;justify-content: center;">
                            <div className="select-image">
                                {this.images}
                            </div>
                        </li>
                        <li>
                            <p Style="color: #fff;">Файл (не больше 7Мбайт)</p>
                            <input onInput={this.readFile} Style="color: #fff;" type="file" />
                        </li>
                        <li>
                            <textarea onInput={this.handleText} Style="margin-top: 1em;border-radius: 4px;min-height: 75px;padding: 1em;background-color: #232c35;color: white;font-family: Montserrat;border: 1px solid #2B3239;" placeholder="Текст" type="text" />
                        </li>

                    </ul>

                </div>
                <div Style="display: flex;justify-content: center;">
                    <FileBlock ref={this.fileRef} text={this.state.text} time={this.state.date} head={this.state.head} type={this.state.type} icon={this.state.img} />
                </div>
                <div Style="display: flex;justify-content: center;">
                    <label style={{color: 'white'}}> <input type="checkbox" onInput={(e) => this.setState({ en: e.target.checked })} /> English</label>
                </div>
                <div Style="display: flex;justify-content: center;">
                    <p className="c-pointer" onClick={this.send} Style="width: 150px;background-color: #232c35;text-align: center;padding: 10px;border-radius: 4px;color: #fff;border: 1px solid #2B3239;">Создать</p>
                </div>
            </div>
        )
    }
}


export default Admin;

