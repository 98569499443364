import React, { Component } from 'react';
import up_blue from './img/up_blue.png';
import down_blue from './img/down_blue.png';
import empty from './img/empty.png';
class SmallBlock extends Component {

    constructor(props) {
        super();
        
        this.state = {
            headText: props.head,
            arr: props.data,
            arr_text: props.arr_text
        };

    }


    refresh = (arr) => {
        this.setState({arr: arr});
    }
    getPercent = (e) => {
        if ((parseFloat(e.replace(',', '.')) * 10) > 100) {
            return parseFloat(e.replace(',', '.'));
        }
        else {
            return parseFloat(e.replace(',', '.')) * 10;
        }
        
    }
    getFirstText = (obj) => {
        if (parseFloat(obj.replace(',', '.')) < 10) {
            return parseFloat(obj.replace(',', '.')).toPrecision(2).replace('.', ',');
        }
        else {
            return (parseFloat(obj.replace(',', '.')) / 10).toPrecision(2).replace('.', ',');
        }
        
    }
    getSecondText = (obj) => {
        if (obj === "0.0" || obj === "-0.0") {
            return "(0,0)";
        } else {
            return '(' + (obj.indexOf('-') === -1 ? "+" : "") + obj.replace('.', ',') + ')';
        }
       
    }
    getIcon = (obj) => {
        var block = up_blue;
        if (parseFloat(obj) === 0) {
            block = empty;
        }
        else if (parseFloat(obj) < 0) {
            block = down_blue;
        }
        return block;
    }
    render() {


        return (
            <div className="info-block-base">
                <div className="info-block-header">
                    <p className="info-block-header-text">{this.state.headText}</p>
                </div>
                
                <div className="chart-line-block">
                    <br />
                    <div className="chart-line-head">
                        <p className="char-line-info">{this.state.arr_text[0]}</p>
                        <span className="small-percent">
                            <span className="chart-first-number">{this.getFirstText(this.state.arr[0][0])}</span>
                            <span Style="width: 5px;"></span>
                            <span className={`chart-second-number ${parseInt(this.state.arr[0][2]) === 0 ? "shape-of-gray" : ""}`}>{this.getSecondText(this.state.arr[0][1])}</span>
                           
                        </span>
                    </div>
                    <div className="chart-line">
                        <div className="char-line-back"></div>
                        <div Style={`width: ${this.getPercent(this.state.arr[0][0])}%`} className="char-line-solid"></div>
                    </div>
                </div>
                <div className="chart-line-block">
                    <br />
                    <div className="chart-line-head">
                        <p className="char-line-info">{this.state.arr_text[1]}</p>
                        <span className="small-percent">
                            <span className="chart-first-number">{this.getFirstText(this.state.arr[1][0])}</span>
                            <span Style="width: 5px;"></span>
                            <span className={`chart-second-number ${parseInt(this.state.arr[1][2]) === 0 ? "shape-of-gray" : ""}`}>{this.getSecondText(this.state.arr[1][1])}</span>
                          
                        </span>
                    </div>
                    <div className="chart-line">
                        <div className="char-line-back"></div>
                        <div Style={`width: ${this.getPercent(this.state.arr[1][0])}%`} className="char-line-solid"></div>
                    </div>
                </div>
                <div className="chart-line-block">
                    <br />
                    <div className="chart-line-head">
                        <p className="char-line-info">{this.state.arr_text[2]}</p>
                        <span className="small-percent">
                            <span className="chart-first-number">{this.getFirstText(this.state.arr[2][0])}</span>
                            <span Style="width: 5px;"></span>
                            <span className={`chart-second-number ${parseInt(this.state.arr[2][2]) === 0 ? "shape-of-gray" : ""}`}>{this.getSecondText(this.state.arr[2][1])}</span>
                          
                        </span>
                    </div>
                    <div className="chart-line">
                        <div className="char-line-back"></div>
                        <div Style={`width: ${this.getPercent(this.state.arr[2][0])}%`} className="char-line-solid"></div>
                    </div>
                </div>
               
            </div>
        )
    }
}


export default SmallBlock;

/*
 * 
 *  
 * 
 *   

 
 */