import React, { Component } from 'react';
import ConvModalBlock from './ConvModalBlock.js';
import closeConv from './img/close_conv.png';

class ConvModal extends Component {

    constructor(props) {
        super();
        this.refPer = React.createRef();
        this.refKult = React.createRef();
        this.refProf = React.createRef();
        this.refSoc = React.createRef();
        this.refWeed = React.createRef();
        this.refMajor = React.createRef();
        this.refBomg = React.createRef();
        this.state = {
            data: props.data
        };


    }
    refreshAll = (data) => {
        this.refreshPer(data);
        this.refreshKult(data);
        this.refreshProf(data);
        this.refreshSoc(data);
        this.refreshWeed(data);
        this.refreshMajor(data);
        this.refreshBomg(data);
    }
    refresh = (data) => {
        
        this.setState({ data: data });
        this.refreshAll(data);
    }
    refreshPer = (data) => {
      
        if (this.refPer.current != null) {
            this.refPer.current.refresh(data[13][1],
                [[data[14][1], data[14][2], window.location.href.indexOf("En") === -1 ? "Вміння жити в нових суспільних умовах" : "Ability to live in new social conditions", data[14][3]],
                    [data[15][1], data[15][2], window.location.href.indexOf("En") === -1 ? "Упевненість в своїх силах" : "Self-confidence", data[15][3]],
                    [data[16][1], data[16][2], window.location.href.indexOf("En") === -1 ? "Рішучість у досягненні своїх цілей" : "Determination in achieving own goals", data[16][3]],
                    [data[17][1], data[17][2], window.location.href.indexOf("En") === -1 ? "Iнiцiатива i самостійність під час розв’язання проблем" : "Initiative and independence in solving problems", data[17][3]]]);
        }
    }
    refreshKult = (data) => {
        if (this.refKult.current != null) {
            this.refKult.current.refresh(data[18][1],
                [
                    [data[19][1], data[19][2], window.location.href.indexOf("En") === -1 ? "Сучасні економічні знання" : "Modern economic knowledge" , data[19][3]],
                    [data[20][1], data[20][2], window.location.href.indexOf("En") === -1 ? "Сучасні політичні знання" : "Modern political knowledge" , data[20][3]]
                ]);
        }
    }
    refreshProf = (data) => {
        if (this.refProf.current != null) {
            this.refProf.current.refresh(data[21][1],
                [
                    [data[22][1], data[22][2], window.location.href.indexOf("En") === -1 ? "Робота, що підходить" : "Suitable work", data[22][3]],
                    [data[23][1], data[23][2], window.location.href.indexOf("En") === -1 ? "Можливість мати додатковий заробіток" : "Opportunity to have extra income", data[23][3]],
                    [data[24][1], data[24][2], window.location.href.indexOf("En") === -1 ? "Можливість працювати з повною віддачею" : "Ability to work hard", data[24][3]]
                ]);
        }
    }
    refreshSoc = (data) => {
        if (this.refSoc.current != null) {
            this.refSoc.current.refresh(data[25][1],
                [
                    [data[26][1], data[26][2], window.location.href.indexOf("En") === -1 ? "Юридична допомога для захисту своїх прав та інтересів" : "Legal assistance to protect rights and interests", data[25][3]]
                ]);
        }
    }
    refreshWeed = (data) => {
        //console.log(data);
        if (this.refWeed.current != null) {
            this.refWeed.current.refresh(data[27][1],
                [
                    [data[28][1], data[28][2], window.location.href.indexOf("En") === -1 ? "Здоров'я" : "Health", data[28][3]],
                    [data[29][1], data[29][2], window.location.href.indexOf("En") === -1 ? "Необхідна медична допомога" : "Medical care", data[29][3]],
                    [data[30][1], data[30][2], window.location.href.indexOf("En") === -1 ? "Повноцінне дозвілля" : "Leisure", data[30][3]],
                    [data[31][1], data[31][2], window.location.href.indexOf("En") === -1 ? "Можливість повноцінно проводити відпустку" : "Opportunity to have proper vacation", data[31][3]]
                ]);
        }
    }
    refreshMajor = (data) => {
        if (this.refMajor.current != null) {
            this.refMajor.current.refresh(data[32][1],
                [
                    [data[33][1], data[33][2], window.location.href.indexOf("En") === -1 ? "Можливість харчуватися відповідно до своїх смаків" : "Ability to eat according to your tastes", data[33][3]],
                    [data[34][1], data[34][2], window.location.href.indexOf("En") === -1 ? "Модний та красивий одяг" : "Fashionable and beautiful clothes", data[34][3]]
                ]);
        }
    }
    refreshBomg = (data) => {
        if (this.refBomg.current != null) {
            this.refBomg.current.refresh(data[35][1],
                [
                    [data[36][1], data[36][2], window.location.href.indexOf("En") === -1 ? "Можливість купувати найнеобхiднiшi продукти" : "Opportunity to buy the most necessary products", data[36][3]],
                    [data[37][1], data[37][2], window.location.href.indexOf("En") === -1 ? "Добротне житло" : "Proper residence", data[37][3]],
                    [data[38][1], data[38][2], window.location.href.indexOf("En") === -1 ? "Необхідний одяг" : "Necessary clothes", data[38][3]],
                    [data[39][1], data[39][2], window.location.href.indexOf("En") === -1 ? "Необхідні меблі" : "Necessary furniture", data[39][3]]
                ]);
        }
    }
    render() {
        var arr = []; for (var i = 0; i < 3; i++) {
            var _arr = [];
            for (var j = 0; j < 7; j++) {
                _arr.push(j);
            }
            arr.push(_arr);

        }
        var temp_dab = arr.map((i) =>
            <div Style="width: 288px;margin-right: 1rem;">
                {i.map((j) =>
                    <div Style="margin-top: 1rem;" className="chart-line-block">
                        <br />
                        <div className="chart-line-head">
                            <p className="char-line-info">Commodo minim irure cupidatat dolor eu Lorem</p>
                            <span Style="position: absolute;right: 0;" className="small-percent">
                                <span className="chart-first-number">5,5</span>
                            </span>
                        </div>
                        <div className="chart-line">
                            <div className="char-line-back"></div>
                            <div Style={`width: ${parseFloat(50)}%`} className="char-line-solid"></div>
                        </div>
                    </div>
                )}

            </div>
        );





        return (

            <div className="conv-modal" >
                <div Style="position: relative;">
                    <img onClick={this.props.close} className="conv-modal-close" src={closeConv} />
                </div>

                <div className="conv-modal-base">
                    <div >
                        <p Style="font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
color: #858B93;
line-height: 20px;">{window.location.href.indexOf("En") === -1 ? "Рівень задоволеності соціальних потреб" : "The level of satisfaction of social needs"}<span Style="font-size: 13px;">{window.location.href.indexOf("En") === -1 ? "(1 – потреба не задоволена; 3 – потреба задоволена)" : "(1 - need not satisfied; 3 - need satisfied)"}</span></p>
                    </div>

                    <ConvModalBlock ref={this.refPer} data={[["2,3", "0,0", window.location.href.indexOf("En") === -1 ? "Вміння жити в нових суспільних умовах" : "Ability to live in new social conditions", "0"],
                        ["2,2", "0,0", window.location.href.indexOf("En") === -1 ? "Упевненість в своїх силах" : "Self-confidence", "0"],
                        ["2,1", "0,0", window.location.href.indexOf("En") === -1 ? "Рішучість у досягненні своїх цілей" : "Determination in achieving own goals", "0"],
                        ["2,3", "-0,1", window.location.href.indexOf("En") === -1 ? "Iнiцiатива i самостійність під час розв’язання проблем" : "Initiative and independence in solving problems", "0"]]}
                        headData={"2,2"} headText={window.location.href.indexOf("En") === -1 ? "Особистісна сфера" : "Personal sphere"} color={"#42CCA2"} />
                    <ConvModalBlock ref={this.refKult}
                        data={[["2,0", "0,1", window.location.href.indexOf("En") === -1 ? "Сучасні економічні знання" : "Modern economic knowledge", "0"],
                            ["2,1", "0,0", window.location.href.indexOf("En") === -1 ? "Сучасні політичні знання" : "Modern political knowledge", "0"]]}
                        headData={"2,1"} headText={window.location.href.indexOf("En") === -1 ? "Інформаційно-культурна сфера" : "Information and cultural needs"} color={"#3BB7A1"} />
                    <ConvModalBlock ref={this.refProf}
                        data={[["1,8", "-0,1", window.location.href.indexOf("En") === -1 ? "Робота, що підходить" : "Suitable work", "0"],
                            ["1,5", "0,0", window.location.href.indexOf("En") === -1 ? "Можливість мати додатковий заробіток" : "Opportunity to have extra income", "0"],
                            ["2,1", "0,0", window.location.href.indexOf("En") === -1 ? "Можливість працювати з повною віддачею" : "Ability to work hard", "0"]]}
                        headData={"1,8"} headText={window.location.href.indexOf("En") === -1 ? "Професійно-трудова сфера" : "Professional and labor"} color={"#33A4A0"} />
                    <ConvModalBlock ref={this.refSoc}
                        data={[["1,7", "0,0", window.location.href.indexOf("En") === -1 ? "Юридична допомога для захисту своїх прав та інтересів" : "Legal assistance to protect rights and interests", "0"]]}
                        headData={"1,7"} headText={window.location.href.indexOf("En") === -1 ? "Соціальна безпека" : "Social security"} color={"#2E95A0"} />
                    <ConvModalBlock ref={this.refWeed}
                        data={[["2,3", "-0,1", window.location.href.indexOf("En") === -1 ? "Здоров'я" : "Health", "0"],
                            ["1,8", "0,1", window.location.href.indexOf("En") === -1 ? "Необхідна медична допомога" : "Medical care", "0"],
                            ["1,8", "-0,2", window.location.href.indexOf("En") === -1 ? "Повноцінне дозвілля" : "Leisure", "0"],
                            ["1,5", "-0,1", window.location.href.indexOf("En") === -1 ? "Можливість повноцінно проводити відпустку" : "Opportunity to have proper vacation", "0"]]}
                        headData={"1,8"} headText={window.location.href.indexOf("En") === -1 ? "Рекреаційно-культурна" : "Recreational needs"} color={"#28849F"} />
                    <ConvModalBlock ref={this.refMajor}
                        data={[["2,2", "0,0", window.location.href.indexOf("En") === -1 ? "Можливість харчуватися відповідно до своїх смаків" : "Ability to eat according to your tastes", "0"],
                            ["2,0", "-0,1", window.location.href.indexOf("En") === -1 ? "Модний та красивий одяг" : "Fashionable and beautiful clothes", "0"]]}
                        headData={"2,1"} headText={window.location.href.indexOf("En") === -1 ? "Матеріально-побутова (2-й рівень)" : "Material and household needs (2nd level)"} color={"#20709E"} />
                    <ConvModalBlock ref={this.refBomg}
                        data={[["2,5", "-0,1", window.location.href.indexOf("En") === -1 ? "Можливість купувати найнеобхiднiшi продукти" : "Opportunity to buy the most necessary products", "0"],
                            ["2,2", "0,0", window.location.href.indexOf("En") === -1 ? "Добротне житло" : "Proper residence", "0"],
                            ["2,5", "-0,2", window.location.href.indexOf("En") === -1 ? "Необхідний одяг" : "Necessary clothes", "0"],
                            ["2,3", "0,0", window.location.href.indexOf("En") === -1 ? "Необхідні меблі" : "Necessary furniture", "0"]]}
                        headData={"2,4"} headText={window.location.href.indexOf("En") === -1 ? "Матеріально-побутова (1-й рівень)" : "Material and household needs (1st level)"} color={"#185B9D"} />
                    <p className="convModal-text" Style="">
                        {window.location.href.indexOf("En") === -1 ? "Нижче під значенням метрики показана динаміка порівняно з попередньою хвилею. Статистично значимі зміни відмічені кольором" : "Below the value of the metric shows the dynamics compared to the previous wave. Statistically significant changes are marked by color"}
                            </p>
                </div>
            </div>


        )
    }
}


export default ConvModal;


/*


 */