import React, { Component } from 'react';
import FileBlock from './FileBlock.js';

class Files extends Component {

    constructor(props) {
        super();

        this.state = {
            data:
                <div style={{ display: 'none' }}><div>
                    <p Style="font-family: Montserrat;font-style: normal;font-weight: normal;font-size: 16px;line-height: 20px;color: #FFFFFF;text-align: center;margin-bottom: 20px;">30 березня – 06 квітня 2020 </p>
                </div>
                    <div className="pre-files">
                        <div className="files">
                            <FileBlock toDownload={"https://youkraine.com.ua/files/Factum_Group_YOU%D0%BA%D1%80%D0%B0%D1%96%CC%88%D0%BD%D0%B0_%D0%BC%D1%96%D0%BD%D1%96%D0%B2%D0%B8%D0%BF%D1%83%D1%81%D0%BA2_%D0%9A%D0%B0%D1%80%D0%B0%D0%BD%D1%82%D0%B8%D0%BD_%D1%96_%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%B0.pdf"} text={"Кожен п'ятий в неоплачуваній відпустці"} time={"01.04.2020"} type={"quiz"} icon={"business_smartphone"} />


                        </div>
                    </div>
                </div>
        };
        var n_this = this;
        window.addEventListener('load', function () {
            n_this.go();
        });
    }

    getBadText = (item) => {
        if (item.howBad === 1) {
            return item.howBadOut;
        } else { return item.finalDate; }
    }

    refresh = (data) => {
       
        var _dat = data.map((item) =>
            <div Style="margin-bottom: 20px">
                <div>
                    <p Style="font-family: Montserrat;font-style: normal;font-weight: normal;font-size: 16px;line-height: 20px;color: #FFFFFF;text-align: center;">
                        {this.getBadText(item)}
                    </p>
                </div>
                <div className="pre-files">
                    <div className="files">
                        {item.reports.map((jitem) =>
                            <FileBlock toDownload={"https://youkraina.com.ua/files/" + jitem.fileName} text={jitem.text} time={jitem.date} type={jitem.type} icon={jitem.img} />

                        )}
                    </div>
                </div>
            </div>);


        this.setState({
            data: _dat
        });
        

    }

    go = () => {
        var link = 'Doshik/GetReports';
        if (window.location.href.indexOf("En") !== -1) {
            link += '?en=true';
        }
        fetch(link)
            .then(x => x.json())
            .then(x => this.refresh(x));
    }
    render() {


        return (
            <div>
                <p onClick={this.go} Style="color: #fff;display: none;">click</p>
                {this.state.data}
            </div>
        )
    }
}


export default Files;

